import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { FormControl, TextField } from '@mui/material';
import { CustomMutationResult, displayUserErrors } from '../util';
import { useDebouncedCallback } from 'use-debounce';

const DEBOUNCE_MS = 1000;
export function GenericField<
  TKey extends string,
  TResult extends CustomMutationResult<TKey>,
>({
  label,
  width,
  value,
  mutationKey,
  mutate,
}: {
  label: string;
  width?: any;
  value: string;
  mutationKey: TKey;
  mutate: (value: string) => Promise<TResult>;
}) {
  const [current, setCurrent] = useState<string | null>(null);

  const doMutation = useDebouncedCallback((value: string) => {
    return displayUserErrors(mutationKey, mutate(value), false)
      .then(() => setCurrent(null))
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
      });
  }, DEBOUNCE_MS);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value as string;
    setCurrent(val);
    doMutation(val);
  };

  // If unmounted, make sure any pending mutations do get applied
  useEffect(
    () => () => {
      doMutation.flush();
    },
    [doMutation],
  );

  return (
    <FormControl style={{ marginTop: '1.5em', width: width ?? 130 }}>
      <TextField
        variant="standard"
        label={label}
        InputLabelProps={{
          shrink: true,
          style: { fontSize: '1.3rem', top: -5 },
        }}
        value={current ?? value}
        onChange={onChange}
      />
    </FormControl>
  );
}
