import { useState } from 'react';
import { SideDrawer } from './SideDrawer';
import { ContentWrapper } from './ContentWrapper';
import Manage from './Manage';
import { useCallback } from 'react';

export interface ManagePageProps {
  drawerOpen: boolean;
  setDrawerOpen: (v: boolean) => void;
}

const ManagePage: React.FC<ManagePageProps> = ({
  drawerOpen,
  setDrawerOpen,
}) => {
  const [selected, setSelected] = useState<string | null>(null);

  const handleDeselect = useCallback(() => {
    setSelected(null);
  }, []);

  return (
    <div>
      <SideDrawer
        selectedId={selected}
        onSelect={setSelected}
        viewType="manage"
        open={drawerOpen}
        setOpen={setDrawerOpen}
      />
      <ContentWrapper drawerOpen={drawerOpen}>
        {selected != null && (
          <Manage
            // By using `key`, will remount and hence flush mutations when
            // selecting something else
            key={selected}
            unitPrintListId={selected}
            onDeselect={handleDeselect}
          />
        )}
      </ContentWrapper>
    </div>
  );
};

export default ManagePage;
