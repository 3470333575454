import { useState, useRef } from 'react';
import * as Sentry from '@sentry/react';
import {
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import Swal from 'sweetalert2';
import {
  UnitPrintListStatus,
  useDeleteUnitPrintListMutation,
  useMarkDraftMutation,
  useMarkReadyMutation,
  usePrintersQuery,
  usePrintSummaryMutation,
  useUnitPrintListQuery,
  useUploadJobsMutation,
} from '../generated/graphql';
import { Loading } from './Loading';
import { Error } from './Error';
import ManageTable from './ManageTable';
import ManageFields from './ManageFields';
import { displayUserErrors } from '../util';
import { env } from '../runtime-environment';

export interface ManageProps {
  unitPrintListId: string;
  onDeselect: () => void;
}

const Manage: React.FC<ManageProps> = ({ unitPrintListId, onDeselect }) => {
  const { data, loading, error } = useUnitPrintListQuery({
    variables: { id: unitPrintListId },
  });

  const [printer, setPrinter] = useState<string | null>(
    env.defaultSummaryPrinter,
  );

  if (loading) return <Loading />;
  if (error || !data?.unitPrintList) return <Error />;

  const list = data.unitPrintList;

  return (
    <Container style={{ width: 800, marginBottom: '2em' }}>
      <Typography
        variant="h1"
        style={{ marginBottom: '1em', fontSize: '2rem' }}
      >
        Manage Unit
      </Typography>
      <ManageFields list={list} />
      <ManageTable jobs={list.printJobs} />
      <div
        style={{
          marginTop: '1em',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <PrintSummaryButton
            unitPrintListId={unitPrintListId}
            printerName={printer}
          />
          <MarkStatus
            unitPrintListId={unitPrintListId}
            currentStatus={list.status}
          />
          <DeleteButton
            unitPrintListId={unitPrintListId}
            onDeleted={onDeselect}
          />
        </div>
        <div>
          <ImportButton unitPrintListId={unitPrintListId} />
        </div>
      </div>
      <SelectPrinter printer={printer} onSetPrinter={setPrinter} />
      <Typography variant="body2" style={{ marginTop: '2.5em' }}>
        To add print jobs, copy the appropriate template from
        <em>\\multi01\Applications\BARTENDER\PRINTFILES\Templates</em>, open the
        copied file, add the data, and click on the "UPLOAD CSV/XLSX" button to
        upload the print jobs. You should fill in the other data first.
      </Typography>
    </Container>
  );
};

const PrintSummaryButton: React.FC<{
  unitPrintListId: string;
  printerName: string | null;
}> = ({ unitPrintListId, printerName }) => {
  const [print_] = usePrintSummaryMutation({
    variables: {
      input: {
        unitPrintListId,
        printerName: printerName!,
      },
    },
  });

  const print = () => {
    if (printerName == null) return;
    displayUserErrors('result', print_(), false)
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Successfully printed unit label summary',
          toast: true,
          position: 'bottom-end',
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
        });
      })
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
      });
  };

  return (
    <Button disabled={printerName == null} onClick={print}>
      Print Unit Sheet
    </Button>
  );
};

const MarkStatus: React.FC<{
  unitPrintListId: string;
  currentStatus: UnitPrintListStatus;
}> = ({ unitPrintListId, currentStatus }) => {
  const [markDraft] = useMarkDraftMutation({
    variables: { input: { unitPrintListId } },
    optimisticResponse: {
      result: {
        __typename: 'UnitPrintListMarkDraftPayload',
        unitPrintList: {
          __typename: 'UnitPrintList',
          id: unitPrintListId,
          status: UnitPrintListStatus.Draft,
        },
        userErrors: [],
      },
    },
  });

  const [markReady] = useMarkReadyMutation({
    variables: { input: { unitPrintListId } },
    optimisticResponse: {
      result: {
        __typename: 'UnitPrintListMarkReadyPayload',
        unitPrintList: {
          __typename: 'UnitPrintList',
          id: unitPrintListId,
          status: UnitPrintListStatus.Ready,
        },
        userErrors: [],
      },
    },
  });

  const toggle = () => {
    displayUserErrors(
      'result',
      (async () => {
        if (currentStatus === UnitPrintListStatus.Draft) {
          return await markReady();
        }
        if (currentStatus === UnitPrintListStatus.Ready) {
          return await markDraft();
        }

        return { data: null };
      })(),
      true,
    );
  };

  const text =
    currentStatus === UnitPrintListStatus.Draft ? 'Mark Ready' : 'Mark Draft';

  return <Button onClick={toggle}>{text}</Button>;
};

const DeleteButton: React.FC<{
  unitPrintListId: string;
  onDeleted: () => void;
}> = ({ unitPrintListId, onDeleted }) => {
  const [delete_] = useDeleteUnitPrintListMutation({
    variables: {
      input: {
        unitPrintListId,
      },
    },
    refetchQueries: ['UnitPrintLists'],
    awaitRefetchQueries: true,
  });

  const handleClick = () => {
    displayUserErrors('result', delete_(), false)
      .then(onDeleted)
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
      });
  };

  return (
    <Button color="error" onClick={handleClick}>
      Delete
    </Button>
  );
};

const ImportButton: React.FC<{
  unitPrintListId: string;
}> = ({ unitPrintListId }) => {
  const [upload] = useUploadJobsMutation();
  const formRef = useRef<HTMLFormElement>(null);

  const onChange = (event: any) => {
    if (!event.target?.validity?.valid) return;
    const file = event.target.files[0];
    displayUserErrors(
      'result',
      upload({
        variables: {
          input: {
            unitPrintListId,
            data: file,
          },
        },
      }),
      true,
    );

    formRef.current?.reset();
  };

  return (
    <form ref={formRef}>
      <Button component="label" onChange={onChange}>
        Upload CSV/XLSX
        <input type="file" hidden />
      </Button>
    </form>
  );
};

const SelectPrinter: React.FC<{
  printer: string | null;
  onSetPrinter: (printer: string | null) => void;
}> = ({ printer, onSetPrinter }) => {
  const { data, loading, error } = usePrintersQuery();

  if (error) return <Error />;

  const printers = loading || !data ? [] : data.printers.map((x) => x.id);
  return (
    <FormControl style={{ marginTop: '1.5em', width: 300 }}>
      <InputLabel
        id="set-printer"
        style={{ fontSize: '1.3rem', top: -5 }}
        shrink
      >
        Printer
      </InputLabel>
      <Select
        variant="standard"
        labelId="set-printer"
        id="set-printer-select"
        value={loading ? '' : printer ?? ''}
        onChange={(e) => {
          const value = e.target.value as string;
          onSetPrinter(value === '' ? null : value);
        }}
      >
        <MenuItem value="" />
        {printers.map((printer) => (
          <MenuItem key={printer} value={printer}>
            {printer}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Manage;
