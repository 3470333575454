import {
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import {
  useFullCustomersQuery,
  usePrintCustomMutation,
} from '../generated/graphql';
import { displayUserErrors } from '../util';
import { ContentWrapper } from './ContentWrapper';
import { Error } from './Error';

const CustomPrintPage: React.FC = () => {
  const { data, loading, error } = useFullCustomersQuery();

  const [customerId, setCustomerId] = useState<string | null>(null);
  const [labelTypeId, setLabelTypeId] = useState<string | null>(null);

  const [productCode, setProductCode] = useState('');
  const [mdc, setMdc] = useState('');
  const [barcode, setBarcode] = useState('');
  const [extraData, setExtraData] = useState(new Map<string, string>());
  const [printQuantityText, setPrintQuantity] = useState<string>('');

  const printQuantity = /^[0-9]+$/.test(printQuantityText)
    ? parseInt(printQuantityText, 10)
    : null;

  const [print] = usePrintCustomMutation({
    variables: {
      input: {
        labels: [
          {
            labelTypeId: labelTypeId!,
            productCode,
            barcode,
            manufactureDateCode: mdc,
            printQuantity: printQuantity!,
            extraData: [...extraData.entries()].map(([k, v]) => ({
              customFieldId: k,
              value: v,
            })),
          },
        ],
      },
    },
  });

  const inputLabelProps = {
    shrink: true,
    style: { fontSize: '1.3rem', top: -5 },
  };

  if (error) return <Error />;

  const customers = loading || !data ? [] : data.unitCustomers;
  const customer = customers.find((x) => x.id === customerId);
  const labelType = customer?.labelTypes.find((x) => x.id === labelTypeId);

  return (
    <ContentWrapper drawerOpen={false}>
      <Container
        style={{
          marginTop: '3em',
          width: 280,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <TextField
          label="Product Code"
          InputLabelProps={inputLabelProps}
          value={productCode}
          onChange={(e) => setProductCode(e.target.value)}
          style={{ marginBottom: '2em' }}
          variant="standard"
        />
        <TextField
          label="Manufacture Date Code"
          InputLabelProps={inputLabelProps}
          value={mdc}
          onChange={(e) => setMdc(e.target.value)}
          style={{ marginBottom: '2em' }}
          variant="standard"
        />
        <TextField
          variant="standard"
          label="Product Barcode"
          InputLabelProps={inputLabelProps}
          value={barcode}
          onChange={(e) => setBarcode(e.target.value)}
          style={{ marginBottom: '2em' }}
        />
        <div style={{ display: 'flex', marginBottom: '2em' }}>
          <FormControl style={{ width: 110, marginRight: 20 }}>
            <InputLabel
              id="set-customer"
              style={{ fontSize: '1.3rem', top: -5 }}
              shrink
            >
              Customer
            </InputLabel>
            <Select
              labelId="set-customer"
              variant="standard"
              id="set-customer-select"
              value={customerId ?? ''}
              onChange={(e) => {
                setLabelTypeId(null);
                setExtraData(new Map());
                setCustomerId((e.target.value as string) || null);
              }}
            >
              <MenuItem value="" />
              {customers.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {customer && (
            <FormControl style={{ width: 110 }}>
              <InputLabel
                id="set-label-type"
                style={{ fontSize: '1.3rem', top: -5 }}
                shrink
              >
                Label Type
              </InputLabel>
              <Select
                labelId="set-label-type"
                variant="standard"
                id="set-label-type-select"
                value={labelTypeId ?? ''}
                onChange={(e) => {
                  setLabelTypeId((e.target.value as string) || null);
                  setExtraData(new Map());
                }}
              >
                <MenuItem value="" />
                {customer?.labelTypes.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </div>
        {labelType?.customFields.map((field) => (
          <TextField
            key={field.id}
            variant="standard"
            label={field.name}
            InputLabelProps={inputLabelProps}
            value={extraData.get(field.id) ?? ''}
            onChange={(e) => {
              setExtraData((data) => {
                const result = new Map(data);
                result.set(field.id, e.target.value);
                return result;
              });
            }}
            style={{ marginBottom: '2em' }}
          />
        ))}
        <TextField
          label="Print Quantity"
          type="number"
          InputLabelProps={inputLabelProps}
          value={printQuantity ?? ''}
          onChange={(e) => setPrintQuantity(e.target.value)}
          style={{ margin: '2em 0' }}
          variant="standard"
        />
        <Button
          // Any other (less trivial) errors are shown to the user with help
          // from the backend anyway, with explanations, unlike the frontend
          // validation
          disabled={
            !productCode ||
            !mdc ||
            !barcode ||
            !printQuantity ||
            customerId == null ||
            labelTypeId == null
          }
          onClick={() => displayUserErrors('result', print(), true)}
        >
          Print Roll
        </Button>
      </Container>
    </ContentWrapper>
  );
};

export default CustomPrintPage;
