import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';

export interface LoadingProps {
  fullscreen?: boolean;
  className?: string;
}

const LoadingStyling = styled.div<LoadingProps>`
  display: flex;
  justify-content: center;

  ${(props) =>
    props.fullscreen &&
    `
    width: 100vw;
    height: 100vh;
    align-items: center;
  `}
`;

export const Loading: React.FC<LoadingProps> = ({ fullscreen, className }) => (
  <LoadingStyling fullscreen={fullscreen} className={className}>
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <CircularProgress />
    </div>
  </LoadingStyling>
);
