import { useState } from 'react';
import * as Sentry from '@sentry/react';
import { SideDrawer } from './SideDrawer';
import { Button } from '@mui/material';
import PrintJobsTable from './PrintJobsTable';
import { displayUserErrors } from '../util';
import { useCompleteUnitPrintListMutation } from '../generated/graphql';
import { ContentWrapper } from './ContentWrapper';
import Swal from 'sweetalert2';

export interface PrintPageProps {
  drawerOpen: boolean;
  setDrawerOpen: (v: boolean) => void;
  completed?: boolean;
}

const PrintPage: React.FC<PrintPageProps> = ({
  drawerOpen,
  setDrawerOpen,
  completed,
}) => {
  const [selected, setSelected] = useState<string | null>(null);

  const handleComplete = useComplete(selected, () => setSelected(null));

  return (
    <div>
      <SideDrawer
        selectedId={selected}
        onSelect={setSelected}
        viewType={completed ? 'completed' : 'print'}
        open={drawerOpen}
        setOpen={setDrawerOpen}
      />
      <ContentWrapper drawerOpen={drawerOpen}>
        {selected != null && (
          <>
            {!completed && (
              <Button
                variant="outlined"
                style={{ position: 'fixed', top: 80, right: 80, zIndex: 100 }}
                onClick={handleComplete}
              >
                Complete List
              </Button>
            )}
            <PrintJobsTable selectedUnitPrintListId={selected} />
          </>
        )}
      </ContentWrapper>
    </div>
  );
};

function useComplete(unitPrintListId: string | null, deselect: () => void) {
  const [complete] = useCompleteUnitPrintListMutation({
    variables: {
      input: {
        unitPrintListId: unitPrintListId!,
      },
    },
    refetchQueries: ['UnitPrintLists'],
    awaitRefetchQueries: true,
  });

  return async () => {
    if (unitPrintListId == null) return;

    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to complete the list?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes, that's fine",
      confirmButtonColor: '#f50057',
    });

    if (result.isConfirmed) {
      await displayUserErrors('result', complete(), false)
        .then(() => deselect())
        .catch((error) => {
          console.error(error);
          Sentry.captureException(error);
        });
    }
  };
}

export default PrintPage;
