import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * If used in a nexus service:
   *   Date without time component, in ISO string format, i.e. `yyyy-mm-dd` only.
   *
   * If used in a non-nexus service:
   * 	Date with time component, in ISO string format.
   */
  Date: string;
  /** Date with time component, in ISO string format. */
  DateTime: string;
  GraphQLUpload: any;
  /** JSON string. */
  JSON: unknown;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};



export type Debtor = {
  __typename?: 'Debtor';
  id: Scalars['ID'];
};

export type DebtorInvoice = {
  __typename?: 'DebtorInvoice';
  id: Scalars['ID'];
};

export type DebtorInvoiceLine = {
  __typename?: 'DebtorInvoiceLine';
  id: Scalars['ID'];
};

/** A durable file containing human-readable information. Can be printed. */
export type Document = {
  __typename?: 'Document';
  /** Unique ID. */
  id: Scalars['ID'];
  /** URL for displaying the document, valid for approximately 30 minutes */
  url: Scalars['String'];
};

export type DocumentGenerateCommandInput = {
  /** The `DocumentTemplate` that should be used, for example 'examples.hello'. */
  templateId: Scalars['ID'];
  /**
   * The data to apply to the `DocumentTemplate` to generate a `Document`.
   * If the `DocumentTemplate` provides default/example data, this is optional.
   */
  data?: Maybe<Scalars['JSON']>;
};

export type DocumentGenerateInput = {
  /** List of commands for generating `Document`s. */
  commands: Array<DocumentGenerateCommandInput>;
};

export type DocumentGeneratePayload = {
  __typename?: 'DocumentGeneratePayload';
  /** `Document`s generated. */
  documents: Array<Document>;
};

export type DocumentPrintCommandInput = {
  /** Location to fetch the document from, before printing. Must be a PDF file. */
  url: Scalars['String'];
  /** ID of the printer to use. */
  printer: Scalars['String'];
  /** Number of copies to print. Cannot be negative. Defaults to 1. */
  copies?: Maybe<Scalars['Int']>;
};

export type DocumentPrintInput = {
  /** List of commands for printing. */
  commands: Array<DocumentPrintCommandInput>;
};

export type DocumentPrintPayload = {
  __typename?: 'DocumentPrintPayload';
  message: Scalars['String'];
};

export enum EmailAttachmentContentKind {
  /** Treat contents as the attachment data itself, encoded as base 64. */
  Base64 = 'BASE64',
  /** Treat contents as a URL to download the attachment data from. */
  DownloadUrl = 'DOWNLOAD_URL'
}

export type EmailSendAttachmentInput = {
  /** The filename the attachment should have. */
  filename: Scalars['String'];
  /** The content type of the attachment, e.g. `application/pdf`. */
  contentType: Scalars['String'];
  /** The raw content of the attachment. */
  content: Scalars['String'];
  /** Describes how to interpret `content`. */
  contentKind: EmailAttachmentContentKind;
};

export type EmailSendEmailInput = {
  /** List of email recipients. */
  to: Array<Scalars['String']>;
  /** List of CC email recipients. If not provided, equivalent to the empty list. */
  cc?: Maybe<Array<Scalars['String']>>;
  /** List of BCC email recipients. If not provided, equivalent to the empty list. */
  bcc?: Maybe<Array<Scalars['String']>>;
  /**
   * The email address acting as the sender. Must be from a domain configured with
   * SendGrid.
   */
  from: Scalars['String'];
  /** Only use if `templateId` is null. */
  subject?: Maybe<Scalars['String']>;
  /** Only use if `templateId` is null. */
  text?: Maybe<Scalars['String']>;
  /** Only use if `templateId` is null. */
  html?: Maybe<Scalars['String']>;
  /** The template id to use for the data (see `templates.json`). */
  templateId?: Maybe<Scalars['ID']>;
  /** Only use if `templateId` is null. */
  templateData?: Maybe<Scalars['JSON']>;
  /** List of attachments to send. */
  attachments: Array<EmailSendAttachmentInput>;
  /** Use to define the header, such as setting Importance levels. */
  headers?: Maybe<Scalars['JSON']>;
};

export type EmailSendInput = {
  emails: Array<EmailSendEmailInput>;
};

export type EmailSendPayload = {
  __typename?: 'EmailSendPayload';
  success: Scalars['Boolean'];
  message: Scalars['String'];
};

/** Email template that can be printed. */
export type EmailTemplate = {
  __typename?: 'EmailTemplate';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Fulfilment = {
  __typename?: 'Fulfilment';
  id: Scalars['ID'];
};

export type FulfilmentLine = {
  __typename?: 'FulfilmentLine';
  id: Scalars['ID'];
};

export type FulfilmentLinePack = {
  __typename?: 'FulfilmentLinePack';
  id: Scalars['ID'];
};



export type Kiln = {
  __typename?: 'Kiln';
  id: Scalars['ID'];
};

export type KilnCharge = {
  __typename?: 'KilnCharge';
  id: Scalars['ID'];
  chargeNumber: Scalars['String'];
};

export type KilnChargeItem = {
  __typename?: 'KilnChargeItem';
  id: Scalars['ID'];
};

export type LabelPrintCommandInput = {
  /** The `LabelTemplate` that should be used. For example 'Porta-FSC'. */
  templateId: Scalars['ID'];
  /** The printer to print to. */
  printerId: Scalars['ID'];
  /** The number of copies to print. Must be nonnegative. */
  printQuantity: Scalars['Int'];
  /**
   * The data to apply to the `DocumentTemplate` to generate a document.
   * If the `DocumentTemplate` provides default/example data, this is optional.
   */
  data: Array<LabelTemplateFieldData>;
};

export type LabelPrintInput = {
  /** List of commands for printing labels. */
  commands: Array<LabelPrintCommandInput>;
};

export type LabelPrintPayload = {
  __typename?: 'LabelPrintPayload';
  message: Scalars['String'];
};

/** Describes a template that can be used to make labels of a particular kind. */
export type LabelTemplate = {
  __typename?: 'LabelTemplate';
  /** Unique identifier. */
  id: Scalars['ID'];
  /** Name of the template. */
  name: Scalars['String'];
  /** Free text, way of loosely categorizing templates (group by category). */
  category?: Maybe<Scalars['String']>;
  /**
   * The printer that should be suggested automatically if printing labels with
   * this template.
   */
  defaultPrinter?: Maybe<Scalars['String']>;
  /** How presets should be handled, or whether allowed at all. */
  presetKind: LabelTemplatePresetKind;
  /** Fields for customizing generated labels. */
  fields: Array<LabelTemplateField>;
  /**
   * Preset data, empty array if none. Can use `age` to get previous versions.
   *   - `age = 0`: current version
   *   - `age = 1`: version before current
   *   - etc.
   */
  presets: Array<Array<Scalars['String']>>;
  /**
   * Preset document, if any, in XLSX format, as a base64 string. Can use `age`
   * in the same was as in the `presets` field.
   */
  presetDocument?: Maybe<Scalars['String']>;
};


/** Describes a template that can be used to make labels of a particular kind. */
export type LabelTemplatePresetsArgs = {
  age?: Maybe<Scalars['Int']>;
};


/** Describes a template that can be used to make labels of a particular kind. */
export type LabelTemplatePresetDocumentArgs = {
  age?: Maybe<Scalars['Int']>;
};

/** A point of customization for labels generated with a `LabelTemplate`. */
export type LabelTemplateField = {
  __typename?: 'LabelTemplateField';
  /** User-friendly field name. */
  name: Scalars['String'];
  /** Null if the field is there for clarity in presets, and not for the label. */
  internalName?: Maybe<Scalars['String']>;
  /** Whether this field should be provided by a preset, or entered manually. */
  preset: Scalars['Boolean'];
  /** Comment or example input to clarify the purpose of the field. */
  extraInfo: Scalars['String'];
  /** Guide as to how the field should be entered. */
  kind: LabelTemplateFieldKind;
  /** For multiline fields, how many lines may typically be used. */
  defaultLineCount?: Maybe<Scalars['Int']>;
};

export type LabelTemplateFieldData = {
  field: Scalars['String'];
  value: Scalars['String'];
};

export enum LabelTemplateFieldKind {
  /** The field should be given normal text. */
  Text = 'TEXT',
  /** The field accepts multiline text. */
  Multiline = 'MULTILINE'
}

export enum LabelTemplatePresetKind {
  /** Cannot use presets for this template */
  None = 'NONE',
  /** Can use presets, but with single-select printing. */
  Single = 'SINGLE',
  /** Can use presets, but with multi-select printing. */
  Multi = 'MULTI'
}

export type LabelTemplateUploadPresetsError = LabelTemplateUploadPresetsNotFoundError | LabelTemplateUploadPresetsInvalidDataError;

export type LabelTemplateUploadPresetsInput = {
  /** The label template to upload presets to. */
  labelTemplateId: Scalars['ID'];
  /** The presets file, in XLSX format. */
  presets: Scalars['GraphQLUpload'];
};

export type LabelTemplateUploadPresetsInvalidDataError = UserError & {
  __typename?: 'LabelTemplateUploadPresetsInvalidDataError';
  message: Scalars['String'];
};

export type LabelTemplateUploadPresetsNotFoundError = UserError & {
  __typename?: 'LabelTemplateUploadPresetsNotFoundError';
  message: Scalars['String'];
};

export type LabelTemplateUploadPresetsPayload = {
  __typename?: 'LabelTemplateUploadPresetsPayload';
  userErrors: Array<LabelTemplateUploadPresetsError>;
};

export type Location = {
  __typename?: 'Location';
  id: Scalars['ID'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Print a label using a `LabelTemplate` and data. */
  labelPrint: LabelPrintPayload;
  /** Generate a `Document` using a `DocumentTemplate` and data. */
  documentGenerate: DocumentGeneratePayload;
  /** Print a `Document` to a printer using a URL. */
  documentPrint: DocumentPrintPayload;
  /** Upload a set of presets to a `LabelTemplate`. */
  labelTemplateUploadPresets: LabelTemplateUploadPresetsPayload;
  /** Send email(s) using SendGrid. */
  emailSend: EmailSendPayload;
  /** Print a custom product label with the given data. */
  productLabelPrintCustom: ProductLabelPrintCustomPayload;
  /** Print using a print job. The parent unit print list must be in ready state. */
  productLabelPrintJobPrint: ProductLabelPrintJobPrintPayload;
  /**
   * Confirm that the given quantity of labels were successfully printed for a
   * print job. This reduces the remaining quantity of labels on the print job.
   * The parent unit print list must be in READY state, and the print job have been
   * printing (`isPrinting = true`).
   */
  productLabelPrintJobConfirmPrinted: ProductLabelPrintJobConfirmPrintedPayload;
  /**
   * Completes the print job, regardless of how much is remaining. This typically
   * won't be used, because by confirming, one reduces the quantity remaining, and
   * when that hits zero, it is considered complete anyway. The parent unit print
   * list must be in READY state.
   */
  productLabelPrintJobComplete: ProductLabelPrintJobCompletePayload;
  /** Create a `UnitPrintList`, which will start as a `DRAFT`. */
  unitPrintListCreate: UnitPrintListCreatePayload;
  /** Soft delete a `UnitPrintList`. */
  unitPrintListDelete: UnitPrintListDeletePayload;
  /** Indicate that there is nothing left to print on the list. */
  unitPrintListComplete: UnitPrintListCompletePayload;
  /**
   * Upload a list of print jobs. The unit print list must be in DRAFT state.
   * This replaces any other print jobs that may be present.
   */
  unitPrintListUploadJobs: UnitPrintListUploadJobsPayload;
  /** Print a hard copy list of the list's print jobs. */
  unitPrintListPrintSummary: UnitPrintListPrintSummaryPayload;
  /**
   * Indicate that the print jobs in the given UnitPrintList are ready for
   * printing. The UnitPrintList must be in DRAFT state.
   */
  unitPrintListMarkReady: UnitPrintListMarkReadyPayload;
  /**
   * Indicate that the print jobs in the given UnitPrintList are not/no longer
   * ready for printing. This would likely be invoked if one had marked the list as
   * ready prematurely, and would like to undo that. The UnitPrintList must be in
   * READY state.
   */
  unitPrintListMarkDraft: UnitPrintListMarkDraftPayload;
  /**
   * Modify the due date of the UnitPrintList. Must be either a DRAFT or a READY
   * UnitPrintList.
   */
  unitPrintListSetDateDue: UnitPrintListSetDateDuePayload;
  /**
   * Modify the manufacture date code of the UnitPrintList. Must be either a DRAFT
   * or a READY UnitPrintList.
   */
  unitPrintListSetManufactureDateCode: UnitPrintListSetManufactureDateCodePayload;
  /**
   * Modify the unit number of the UnitPrintList. Must be either a DRAFT or a READY
   * UnitPrintList. Note that the unit number must be globally unique.
   */
  unitPrintListSetUnitNumber: UnitPrintListSetUnitNumberPayload;
  /**
   * Sets the customer on the UnitPrintList. The UnitPrintList must be in DRAFT
   * state. This will remove all print jobs from the UnitPrintList, because print
   * jobs tend not to be compatible with different customers (different customers
   * have different custom fields). Hence the UI should probably warn before
   * triggering this. Because of how significant such a change is, it is not
   * possible in READY state unlike for instance setting the due date.
   */
  unitPrintListSetCustomer: UnitPrintListSetCustomerPayload;
};


export type MutationLabelPrintArgs = {
  input: LabelPrintInput;
};


export type MutationDocumentGenerateArgs = {
  input: DocumentGenerateInput;
};


export type MutationDocumentPrintArgs = {
  input: DocumentPrintInput;
};


export type MutationLabelTemplateUploadPresetsArgs = {
  input: LabelTemplateUploadPresetsInput;
};


export type MutationEmailSendArgs = {
  input: EmailSendInput;
};


export type MutationProductLabelPrintCustomArgs = {
  input: ProductLabelPrintCustomInput;
};


export type MutationProductLabelPrintJobPrintArgs = {
  input: ProductLabelPrintJobPrintInput;
};


export type MutationProductLabelPrintJobConfirmPrintedArgs = {
  input: ProductLabelPrintJobConfirmPrintedInput;
};


export type MutationProductLabelPrintJobCompleteArgs = {
  input: ProductLabelPrintJobCompleteInput;
};


export type MutationUnitPrintListCreateArgs = {
  input: UnitPrintListCreateInput;
};


export type MutationUnitPrintListDeleteArgs = {
  input: UnitPrintListDeleteInput;
};


export type MutationUnitPrintListCompleteArgs = {
  input: UnitPrintListCompleteInput;
};


export type MutationUnitPrintListUploadJobsArgs = {
  input: UnitPrintListUploadJobsInput;
};


export type MutationUnitPrintListPrintSummaryArgs = {
  input: UnitPrintListPrintSummaryInput;
};


export type MutationUnitPrintListMarkReadyArgs = {
  input: UnitPrintListMarkReadyInput;
};


export type MutationUnitPrintListMarkDraftArgs = {
  input: UnitPrintListMarkDraftInput;
};


export type MutationUnitPrintListSetDateDueArgs = {
  input: UnitPrintListSetDateDueInput;
};


export type MutationUnitPrintListSetManufactureDateCodeArgs = {
  input: UnitPrintListSetManufactureDateCodeInput;
};


export type MutationUnitPrintListSetUnitNumberArgs = {
  input: UnitPrintListSetUnitNumberInput;
};


export type MutationUnitPrintListSetCustomerArgs = {
  input: UnitPrintListSetCustomerInput;
};

export type PackWorkstation = {
  __typename?: 'PackWorkstation';
  id: Scalars['ID'];
};

/** A device (physical or not) that `Document`s can be printed to. */
export type Printer = {
  __typename?: 'Printer';
  /** Unique ID for the printer */
  id: Scalars['ID'];
};

export type ProductLabelPrintCustomError = ProductLabelPrintCustomLabelTypeNotFoundError | ProductLabelPrintCustomInvalidDataError;

export type ProductLabelPrintCustomInput = {
  /** The list of print commands. */
  labels: Array<ProductLabelPrintCustomLabelInput>;
};

export type ProductLabelPrintCustomInvalidDataError = UserError & {
  __typename?: 'ProductLabelPrintCustomInvalidDataError';
  message: Scalars['String'];
};

export type ProductLabelPrintCustomLabelExtraDataInput = {
  /** The custom field to provide a value for. */
  customFieldId: Scalars['ID'];
  /** The field's value */
  value: Scalars['String'];
};

export type ProductLabelPrintCustomLabelInput = {
  /** Product code on labels. */
  productCode: Scalars['String'];
  /** MDC on labels. */
  manufactureDateCode: Scalars['String'];
  /** Barcode on labels. */
  barcode: Scalars['String'];
  /** Number of labels to print. */
  printQuantity: Scalars['Int'];
  /** Type of the label. */
  labelTypeId: Scalars['ID'];
  extraData: Array<ProductLabelPrintCustomLabelExtraDataInput>;
};

export type ProductLabelPrintCustomLabelTypeNotFoundError = UserError & {
  __typename?: 'ProductLabelPrintCustomLabelTypeNotFoundError';
  message: Scalars['String'];
};

export type ProductLabelPrintCustomPayload = {
  __typename?: 'ProductLabelPrintCustomPayload';
  userErrors: Array<ProductLabelPrintCustomError>;
};

export type ProductLabelPrintJob = {
  __typename?: 'ProductLabelPrintJob';
  /** Unique identifier. */
  id: Scalars['ID'];
  /**
   * Whether a roll label is actively being printed.This is set when a user
   * triggers printing, and unset once the user confirms that a roll was
   * successfully printed. This gives the user the opportunity to reprint a roll if
   * something goes wrong.
   */
  isPrinting: Scalars['Boolean'];
  /** The number of labels required in total for this print job. */
  labelsRequired: Scalars['Int'];
  /** The number of labels left to print in this print job. */
  labelsRemaining: Scalars['Int'];
  /** The type of label to use. */
  labelType: ProductLabelType;
  /** The barcode on the label. */
  barcode: Scalars['String'];
  /** The product code on the label */
  productCode: Scalars['String'];
  /**
   * Extra data to put on the label. Which fields and values should be specified
   * depends on the `labelType`.
   */
  extraData: Array<ProductLabelPrintJobExtraData>;
};

export type ProductLabelPrintJobCompleteError = ProductLabelPrintJobCompleteNotFoundError;

export type ProductLabelPrintJobCompleteInput = {
  /** The print job to complete. */
  printJobId: Scalars['ID'];
};

export type ProductLabelPrintJobCompleteNotFoundError = UserError & {
  __typename?: 'ProductLabelPrintJobCompleteNotFoundError';
  message: Scalars['String'];
};

export type ProductLabelPrintJobCompletePayload = {
  __typename?: 'ProductLabelPrintJobCompletePayload';
  /** The print job modified. */
  printJob?: Maybe<ProductLabelPrintJob>;
  userErrors: Array<ProductLabelPrintJobCompleteError>;
};

export type ProductLabelPrintJobConfirmPrintedError = ProductLabelPrintJobConfirmPrintedNotFoundError | ProductLabelPrintJobConfirmPrintedNotPrintingError | ProductLabelPrintJobConfirmPrintedInvalidQuantityError;

export type ProductLabelPrintJobConfirmPrintedInput = {
  /** The print job to confirm. */
  printJobId: Scalars['ID'];
  /** The number of labels printed successfully. */
  printedQuantity: Scalars['Int'];
};

export type ProductLabelPrintJobConfirmPrintedInvalidQuantityError = UserError & {
  __typename?: 'ProductLabelPrintJobConfirmPrintedInvalidQuantityError';
  message: Scalars['String'];
};

export type ProductLabelPrintJobConfirmPrintedNotFoundError = UserError & {
  __typename?: 'ProductLabelPrintJobConfirmPrintedNotFoundError';
  message: Scalars['String'];
};

export type ProductLabelPrintJobConfirmPrintedNotPrintingError = UserError & {
  __typename?: 'ProductLabelPrintJobConfirmPrintedNotPrintingError';
  message: Scalars['String'];
};

export type ProductLabelPrintJobConfirmPrintedPayload = {
  __typename?: 'ProductLabelPrintJobConfirmPrintedPayload';
  /** The print job modified. */
  printJob?: Maybe<ProductLabelPrintJob>;
  userErrors: Array<ProductLabelPrintJobConfirmPrintedError>;
};

export type ProductLabelPrintJobExtraData = {
  __typename?: 'ProductLabelPrintJobExtraData';
  /** Unique identifier. */
  id: Scalars['ID'];
  /** Custom field definition which gives meaning to `value`. */
  field: ProductLabelTypeCustomField;
  /** The value for the `field` to display on the printed labels. */
  value: Scalars['String'];
};

export type ProductLabelPrintJobPrintError = ProductLabelPrintJobPrintNotFoundError | ProductLabelPrintJobPrintInvalidQuantityError;

export type ProductLabelPrintJobPrintInput = {
  /** The print job to print a batch of. */
  printJobId: Scalars['ID'];
  /** The number of labels to print in this batch. */
  printQuantity: Scalars['Int'];
  /**
   * If set, will print even if the job or the print list is complete, and will not
   * mark it as printing.
   */
  extra?: Maybe<Scalars['Boolean']>;
};

export type ProductLabelPrintJobPrintInvalidQuantityError = UserError & {
  __typename?: 'ProductLabelPrintJobPrintInvalidQuantityError';
  message: Scalars['String'];
};

export type ProductLabelPrintJobPrintNotFoundError = UserError & {
  __typename?: 'ProductLabelPrintJobPrintNotFoundError';
  message: Scalars['String'];
};

export type ProductLabelPrintJobPrintPayload = {
  __typename?: 'ProductLabelPrintJobPrintPayload';
  /** The print job just started printing. */
  printJob?: Maybe<ProductLabelPrintJob>;
  userErrors: Array<ProductLabelPrintJobPrintError>;
};

export type ProductLabelType = {
  __typename?: 'ProductLabelType';
  /** Unique identifier. */
  id: Scalars['ID'];
  /** User-facing name for the label type. */
  name: Scalars['String'];
  /** Path to the printer to use when printing labels of this type. */
  printerLocation: Scalars['String'];
  /**
   * The maximum number of labels that can be printed onto a single roll, for this
   * type.
   */
  maxRollQuantity: Scalars['Int'];
  /** List of custom fields defined for this label type. */
  customFields: Array<ProductLabelTypeCustomField>;
};

export type ProductLabelTypeCustomField = {
  __typename?: 'ProductLabelTypeCustomField';
  /** Unique identifier. */
  id: Scalars['ID'];
  /** User-facing name for the field. */
  name: Scalars['String'];
};

export type PurchaseOrderPack = {
  __typename?: 'PurchaseOrderPack';
  id: Scalars['ID'];
};

export type Query = {
  __typename?: 'Query';
  /** The list of printers that can be printed to */
  printers: Array<Printer>;
  /** Get a printer by ID if it exists. If a printer is returned, it may be printed to. */
  printer?: Maybe<Printer>;
  /** Get a `LabelTemplate` by ID if it exists, or null if none found. */
  labelTemplate?: Maybe<LabelTemplate>;
  /** Returns a list of all/many `LabelTemplate`s. */
  labelTemplates: Array<LabelTemplate>;
  /** List of email templates available for printing. */
  emailTemplates: Array<EmailTemplate>;
  /** Fetch a single `unitCustomer` by id. */
  unitCustomer?: Maybe<UnitCustomer>;
  /** Fetch all or many `unitCustomers`. */
  unitCustomers: Array<UnitCustomer>;
  /** Fetch a single `UnitPrintList` by ID. */
  unitPrintList?: Maybe<UnitPrintList>;
  /** Fetch many `UnitPrintList`s by ID.` */
  unitPrintLists: Array<UnitPrintList>;
  _null?: Maybe<Scalars['String']>;
};


export type QueryPrinterArgs = {
  id: Scalars['ID'];
};


export type QueryLabelTemplateArgs = {
  id: Scalars['ID'];
};


export type QueryLabelTemplatesArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type QueryUnitCustomerArgs = {
  id: Scalars['ID'];
};


export type QueryUnitCustomersArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type QueryUnitPrintListArgs = {
  id: Scalars['ID'];
};


export type QueryUnitPrintListsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
  take?: Maybe<Scalars['Int']>;
  statuses?: Maybe<Array<UnitPrintListStatus>>;
};

export type Run = {
  __typename?: 'Run';
  id: Scalars['ID'];
};

export type RunInfeedPack = {
  __typename?: 'RunInfeedPack';
  packId: Scalars['ID'];
};

export type RunOutfeedPack = {
  __typename?: 'RunOutfeedPack';
  packId: Scalars['ID'];
};

export type SalesBranch = {
  __typename?: 'SalesBranch';
  id: Scalars['ID'];
};

export type SalesOrder = {
  __typename?: 'SalesOrder';
  id: Scalars['ID'];
};

export type SalesOrderLine = {
  __typename?: 'SalesOrderLine';
  id: Scalars['ID'];
};

export type ShippingProvider = {
  __typename?: 'ShippingProvider';
  id: Scalars['ID'];
};

export type StockGroup = {
  __typename?: 'StockGroup';
  id: Scalars['ID'];
};

export type StockItem = {
  __typename?: 'StockItem';
  stockCode: Scalars['ID'];
};

export type StockLocation = {
  __typename?: 'StockLocation';
  id: Scalars['ID'];
};

export type UnitCustomer = {
  __typename?: 'UnitCustomer';
  /** Unique identifier. */
  id: Scalars['ID'];
  /** Name of the customer. */
  name: Scalars['String'];
  /**
   * Whether the customer may be used for future unit print lists. This can be used
   * as a simple way of safely "removing" customers, or even versioning customers.
   * To version customers, you could for example create a copy of the customer in
   * the customer (JSON) config file, with different ids, and different data. The
   * older version of the same customer can then be deactivated.
   */
  active: Scalars['Boolean'];
  /** The different types of labels applicable to this customer. */
  labelTypes: Array<ProductLabelType>;
};

export type UnitPrintList = {
  __typename?: 'UnitPrintList';
  /** Unique identifier. */
  id: Scalars['ID'];
  /** Unit number identifying what the print jobs are for. Unique. */
  unitNumber: Scalars['String'];
  /** Optional due date by which time the print list should be completed. */
  dateDue?: Maybe<Scalars['Date']>;
  /** Date of creation. */
  dateCreated: Scalars['DateTime'];
  /** The customer the `unitNumber` is associated with */
  customer: UnitCustomer;
  /** Freeform date field for manufacturing */
  manufactureDateCode: Scalars['String'];
  /** The status of the print list */
  status: UnitPrintListStatus;
  /** The list of things that require printing. */
  printJobs: Array<ProductLabelPrintJob>;
};

export type UnitPrintListCompleteError = UnitPrintListCompleteNotFoundError;

export type UnitPrintListCompleteInput = {
  /** The unit print list to complete. */
  unitPrintListId: Scalars['ID'];
};

export type UnitPrintListCompleteNotFoundError = UserError & {
  __typename?: 'UnitPrintListCompleteNotFoundError';
  message: Scalars['String'];
};

export type UnitPrintListCompletePayload = {
  __typename?: 'UnitPrintListCompletePayload';
  /** The completed `UnitPrintList`, null on failure. */
  unitPrintList?: Maybe<UnitPrintList>;
  userErrors: Array<UnitPrintListCompleteError>;
};

export type UnitPrintListCreateCustomerNotFoundError = UserError & {
  __typename?: 'UnitPrintListCreateCustomerNotFoundError';
  message: Scalars['String'];
};

export type UnitPrintListCreateError = UnitPrintListCreateCustomerNotFoundError | UnitPrintListCreateInvalidUnitNumberError;

export type UnitPrintListCreateInput = {
  /** Unit number to use. Must be unique. */
  unitNumber?: Maybe<Scalars['String']>;
  /** Date by which the unit print list is expected to be `COMPLETED`. */
  dateDue?: Maybe<Scalars['Date']>;
  /** The customer which is buying the products the labels would go on. */
  customerId: Scalars['ID'];
  /** Freeform date field for manufacturing */
  manufactureDateCode?: Maybe<Scalars['String']>;
};

export type UnitPrintListCreateInvalidUnitNumberError = UserError & {
  __typename?: 'UnitPrintListCreateInvalidUnitNumberError';
  message: Scalars['String'];
};

export type UnitPrintListCreatePayload = {
  __typename?: 'UnitPrintListCreatePayload';
  /** The created `UnitPrintList`, null on failure. */
  unitPrintList?: Maybe<UnitPrintList>;
  userErrors: Array<UnitPrintListCreateError>;
};

export type UnitPrintListDeleteError = UnitPrintListDeleteNotFoundError;

export type UnitPrintListDeleteInput = {
  /** The unit print list to soft delete. */
  unitPrintListId: Scalars['ID'];
};

export type UnitPrintListDeleteNotFoundError = UserError & {
  __typename?: 'UnitPrintListDeleteNotFoundError';
  message: Scalars['String'];
};

export type UnitPrintListDeletePayload = {
  __typename?: 'UnitPrintListDeletePayload';
  userErrors: Array<UnitPrintListDeleteError>;
};

export type UnitPrintListMarkDraftError = UnitPrintListMarkDraftNotFoundError;

export type UnitPrintListMarkDraftInput = {
  /** The unit print list to mark as a draft. */
  unitPrintListId: Scalars['ID'];
};

export type UnitPrintListMarkDraftNotFoundError = UserError & {
  __typename?: 'UnitPrintListMarkDraftNotFoundError';
  message: Scalars['String'];
};

export type UnitPrintListMarkDraftPayload = {
  __typename?: 'UnitPrintListMarkDraftPayload';
  /** The modified `UnitPrintList`, null on failure. */
  unitPrintList?: Maybe<UnitPrintList>;
  userErrors: Array<UnitPrintListMarkDraftError>;
};

export type UnitPrintListMarkReadyError = UnitPrintListMarkReadyNotFoundError;

export type UnitPrintListMarkReadyInput = {
  /** The unit print list to mark as ready. */
  unitPrintListId: Scalars['ID'];
};

export type UnitPrintListMarkReadyNotFoundError = UserError & {
  __typename?: 'UnitPrintListMarkReadyNotFoundError';
  message: Scalars['String'];
};

export type UnitPrintListMarkReadyPayload = {
  __typename?: 'UnitPrintListMarkReadyPayload';
  /** The modified `UnitPrintList`, null on failure. */
  unitPrintList?: Maybe<UnitPrintList>;
  userErrors: Array<UnitPrintListMarkReadyError>;
};

export type UnitPrintListPrintSummaryError = UnitPrintListPrintSummaryNotFoundError;

export type UnitPrintListPrintSummaryInput = {
  /** The unit print list to complete. */
  unitPrintListId: Scalars['ID'];
  /** The printer to print to. */
  printerName: Scalars['String'];
};

export type UnitPrintListPrintSummaryNotFoundError = UserError & {
  __typename?: 'UnitPrintListPrintSummaryNotFoundError';
  message: Scalars['String'];
};

export type UnitPrintListPrintSummaryPayload = {
  __typename?: 'UnitPrintListPrintSummaryPayload';
  userErrors: Array<UnitPrintListPrintSummaryError>;
};

export type UnitPrintListSetCustomerCustomerNotFoundError = UserError & {
  __typename?: 'UnitPrintListSetCustomerCustomerNotFoundError';
  message: Scalars['String'];
};

export type UnitPrintListSetCustomerError = UnitPrintListSetCustomerNotFoundError | UnitPrintListSetCustomerCustomerNotFoundError;

export type UnitPrintListSetCustomerInput = {
  /** The unit print list to modify. */
  unitPrintListId: Scalars['ID'];
  /** The new due date. */
  customerId: Scalars['ID'];
};

export type UnitPrintListSetCustomerNotFoundError = UserError & {
  __typename?: 'UnitPrintListSetCustomerNotFoundError';
  message: Scalars['String'];
};

export type UnitPrintListSetCustomerPayload = {
  __typename?: 'UnitPrintListSetCustomerPayload';
  /** The modified `UnitPrintList`, null on failure. */
  unitPrintList?: Maybe<UnitPrintList>;
  userErrors: Array<UnitPrintListSetCustomerError>;
};

export type UnitPrintListSetDateDueError = UnitPrintListSetDateDueNotFoundError;

export type UnitPrintListSetDateDueInput = {
  /** The unit print list to modify. */
  unitPrintListId: Scalars['ID'];
  /** The new due date. */
  dateDue?: Maybe<Scalars['Date']>;
};

export type UnitPrintListSetDateDueNotFoundError = UserError & {
  __typename?: 'UnitPrintListSetDateDueNotFoundError';
  message: Scalars['String'];
};

export type UnitPrintListSetDateDuePayload = {
  __typename?: 'UnitPrintListSetDateDuePayload';
  /** The modified `UnitPrintList`, null on failure. */
  unitPrintList?: Maybe<UnitPrintList>;
  userErrors: Array<UnitPrintListSetDateDueError>;
};

export type UnitPrintListSetManufactureDateCodeError = UnitPrintListSetManufactureDateCodeNotFoundError;

export type UnitPrintListSetManufactureDateCodeInput = {
  /** The unit print list to modify. */
  unitPrintListId: Scalars['ID'];
  /** The new manufacture date code. */
  manufactureDateCode: Scalars['String'];
};

export type UnitPrintListSetManufactureDateCodeNotFoundError = UserError & {
  __typename?: 'UnitPrintListSetManufactureDateCodeNotFoundError';
  message: Scalars['String'];
};

export type UnitPrintListSetManufactureDateCodePayload = {
  __typename?: 'UnitPrintListSetManufactureDateCodePayload';
  /** The modified `UnitPrintList`, null on failure. */
  unitPrintList?: Maybe<UnitPrintList>;
  userErrors: Array<UnitPrintListSetManufactureDateCodeError>;
};

export type UnitPrintListSetUnitNumberError = UnitPrintListSetUnitNumberNotFoundError | UnitPrintListSetUnitNumberInvalidUnitNumberError;

export type UnitPrintListSetUnitNumberInput = {
  /** The unit print list to modify. */
  unitPrintListId: Scalars['ID'];
  /** The new unit number. */
  unitNumber: Scalars['String'];
};

export type UnitPrintListSetUnitNumberInvalidUnitNumberError = UserError & {
  __typename?: 'UnitPrintListSetUnitNumberInvalidUnitNumberError';
  message: Scalars['String'];
};

export type UnitPrintListSetUnitNumberNotFoundError = UserError & {
  __typename?: 'UnitPrintListSetUnitNumberNotFoundError';
  message: Scalars['String'];
};

export type UnitPrintListSetUnitNumberPayload = {
  __typename?: 'UnitPrintListSetUnitNumberPayload';
  /** The modified `UnitPrintList`, null on failure. */
  unitPrintList?: Maybe<UnitPrintList>;
  userErrors: Array<UnitPrintListSetUnitNumberError>;
};

/** Identifies what phase the unit print list is in. */
export enum UnitPrintListStatus {
  /** Just been created, not yet ready for printing. */
  Draft = 'DRAFT',
  /** May be printed. */
  Ready = 'READY',
  /** Finished printing everything. */
  Completed = 'COMPLETED',
  /** Was deleted. */
  Deleted = 'DELETED'
}

export type UnitPrintListUploadJobsError = UnitPrintListUploadJobsNotFoundError | UnitPrintListUploadJobsInvalidDataError;

export type UnitPrintListUploadJobsInput = {
  /** The unit print list to complete. */
  unitPrintListId: Scalars['ID'];
  /** List of print jobs to upload, in XLSX or CSV format. */
  data: Scalars['Upload'];
};

export type UnitPrintListUploadJobsInvalidDataError = UserError & {
  __typename?: 'UnitPrintListUploadJobsInvalidDataError';
  message: Scalars['String'];
};

export type UnitPrintListUploadJobsNotFoundError = UserError & {
  __typename?: 'UnitPrintListUploadJobsNotFoundError';
  message: Scalars['String'];
};

export type UnitPrintListUploadJobsPayload = {
  __typename?: 'UnitPrintListUploadJobsPayload';
  /** The completed `UnitPrintList`, null on failure. */
  unitPrintList?: Maybe<UnitPrintList>;
  userErrors: Array<UnitPrintListUploadJobsError>;
};


export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
};

export type UserError = {
  message: Scalars['String'];
};

export type WipSalesOrder = {
  __typename?: 'WipSalesOrder';
  id: Scalars['ID'];
};

export type Workstation = {
  __typename?: 'Workstation';
  id: Scalars['ID'];
};

export type UnitPrintListsQueryVariables = Exact<{
  statuses: Array<UnitPrintListStatus> | UnitPrintListStatus;
  take?: Maybe<Scalars['Int']>;
}>;


export type UnitPrintListsQuery = (
  { __typename?: 'Query' }
  & { unitPrintLists: Array<(
    { __typename?: 'UnitPrintList' }
    & Pick<UnitPrintList, 'id' | 'status' | 'unitNumber' | 'dateDue'>
    & { customer: (
      { __typename?: 'UnitCustomer' }
      & Pick<UnitCustomer, 'id' | 'name'>
    ) }
  )> }
);

export type UnitPrintListQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UnitPrintListQuery = (
  { __typename?: 'Query' }
  & { unitPrintList?: Maybe<(
    { __typename?: 'UnitPrintList' }
    & Pick<UnitPrintList, 'id' | 'status' | 'unitNumber' | 'dateDue' | 'manufactureDateCode'>
    & { customer: (
      { __typename?: 'UnitCustomer' }
      & Pick<UnitCustomer, 'id'>
    ), printJobs: Array<(
      { __typename?: 'ProductLabelPrintJob' }
      & Pick<ProductLabelPrintJob, 'id' | 'barcode' | 'productCode' | 'isPrinting' | 'labelsRemaining' | 'labelsRequired'>
      & { labelType: (
        { __typename?: 'ProductLabelType' }
        & Pick<ProductLabelType, 'id' | 'name' | 'maxRollQuantity'>
      ) }
    )> }
  )> }
);

export type CustomerIdsQueryVariables = Exact<{ [key: string]: never; }>;


export type CustomerIdsQuery = (
  { __typename?: 'Query' }
  & { unitCustomers: Array<(
    { __typename?: 'UnitCustomer' }
    & Pick<UnitCustomer, 'id'>
  )> }
);

export type CustomersQueryVariables = Exact<{ [key: string]: never; }>;


export type CustomersQuery = (
  { __typename?: 'Query' }
  & { unitCustomers: Array<(
    { __typename?: 'UnitCustomer' }
    & Pick<UnitCustomer, 'id' | 'name'>
  )> }
);

export type FullCustomersQueryVariables = Exact<{ [key: string]: never; }>;


export type FullCustomersQuery = (
  { __typename?: 'Query' }
  & { unitCustomers: Array<(
    { __typename?: 'UnitCustomer' }
    & Pick<UnitCustomer, 'id' | 'name'>
    & { labelTypes: Array<(
      { __typename?: 'ProductLabelType' }
      & Pick<ProductLabelType, 'id' | 'name' | 'maxRollQuantity'>
      & { customFields: Array<(
        { __typename?: 'ProductLabelTypeCustomField' }
        & Pick<ProductLabelTypeCustomField, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type PrintersQueryVariables = Exact<{ [key: string]: never; }>;


export type PrintersQuery = (
  { __typename?: 'Query' }
  & { printers: Array<(
    { __typename?: 'Printer' }
    & Pick<Printer, 'id'>
  )> }
);

export type CreateUnitPrintListMutationVariables = Exact<{
  input: UnitPrintListCreateInput;
}>;


export type CreateUnitPrintListMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'UnitPrintListCreatePayload' }
    & { unitPrintList?: Maybe<(
      { __typename?: 'UnitPrintList' }
      & Pick<UnitPrintList, 'id' | 'status' | 'dateCreated' | 'dateDue' | 'manufactureDateCode' | 'unitNumber'>
      & { customer: (
        { __typename?: 'UnitCustomer' }
        & Pick<UnitCustomer, 'id'>
      ), printJobs: Array<(
        { __typename?: 'ProductLabelPrintJob' }
        & Pick<ProductLabelPrintJob, 'id'>
      )> }
    )>, userErrors: Array<(
      { __typename: 'UnitPrintListCreateCustomerNotFoundError' }
      & Pick<UnitPrintListCreateCustomerNotFoundError, 'message'>
    ) | (
      { __typename: 'UnitPrintListCreateInvalidUnitNumberError' }
      & Pick<UnitPrintListCreateInvalidUnitNumberError, 'message'>
    )> }
  ) }
);

export type PrintSummaryMutationVariables = Exact<{
  input: UnitPrintListPrintSummaryInput;
}>;


export type PrintSummaryMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'UnitPrintListPrintSummaryPayload' }
    & { userErrors: Array<(
      { __typename: 'UnitPrintListPrintSummaryNotFoundError' }
      & Pick<UnitPrintListPrintSummaryNotFoundError, 'message'>
    )> }
  ) }
);

export type MarkReadyMutationVariables = Exact<{
  input: UnitPrintListMarkReadyInput;
}>;


export type MarkReadyMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'UnitPrintListMarkReadyPayload' }
    & { unitPrintList?: Maybe<(
      { __typename?: 'UnitPrintList' }
      & Pick<UnitPrintList, 'id' | 'status'>
    )>, userErrors: Array<(
      { __typename: 'UnitPrintListMarkReadyNotFoundError' }
      & Pick<UnitPrintListMarkReadyNotFoundError, 'message'>
    )> }
  ) }
);

export type MarkDraftMutationVariables = Exact<{
  input: UnitPrintListMarkDraftInput;
}>;


export type MarkDraftMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'UnitPrintListMarkDraftPayload' }
    & { unitPrintList?: Maybe<(
      { __typename?: 'UnitPrintList' }
      & Pick<UnitPrintList, 'id' | 'status'>
    )>, userErrors: Array<(
      { __typename: 'UnitPrintListMarkDraftNotFoundError' }
      & Pick<UnitPrintListMarkDraftNotFoundError, 'message'>
    )> }
  ) }
);

export type DeleteUnitPrintListMutationVariables = Exact<{
  input: UnitPrintListDeleteInput;
}>;


export type DeleteUnitPrintListMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'UnitPrintListDeletePayload' }
    & { userErrors: Array<(
      { __typename: 'UnitPrintListDeleteNotFoundError' }
      & Pick<UnitPrintListDeleteNotFoundError, 'message'>
    )> }
  ) }
);

export type UploadJobsMutationVariables = Exact<{
  input: UnitPrintListUploadJobsInput;
}>;


export type UploadJobsMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'UnitPrintListUploadJobsPayload' }
    & { unitPrintList?: Maybe<(
      { __typename?: 'UnitPrintList' }
      & Pick<UnitPrintList, 'id'>
      & { printJobs: Array<(
        { __typename?: 'ProductLabelPrintJob' }
        & Pick<ProductLabelPrintJob, 'id' | 'barcode' | 'productCode' | 'isPrinting' | 'labelsRemaining' | 'labelsRequired'>
        & { labelType: (
          { __typename?: 'ProductLabelType' }
          & Pick<ProductLabelType, 'id' | 'name' | 'maxRollQuantity'>
        ) }
      )> }
    )>, userErrors: Array<(
      { __typename: 'UnitPrintListUploadJobsNotFoundError' }
      & Pick<UnitPrintListUploadJobsNotFoundError, 'message'>
    ) | (
      { __typename: 'UnitPrintListUploadJobsInvalidDataError' }
      & Pick<UnitPrintListUploadJobsInvalidDataError, 'message'>
    )> }
  ) }
);

export type CompleteUnitPrintListMutationVariables = Exact<{
  input: UnitPrintListCompleteInput;
}>;


export type CompleteUnitPrintListMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'UnitPrintListCompletePayload' }
    & { unitPrintList?: Maybe<(
      { __typename?: 'UnitPrintList' }
      & Pick<UnitPrintList, 'id' | 'status'>
    )>, userErrors: Array<(
      { __typename: 'UnitPrintListCompleteNotFoundError' }
      & Pick<UnitPrintListCompleteNotFoundError, 'message'>
    )> }
  ) }
);

export type SetCustomerMutationVariables = Exact<{
  input: UnitPrintListSetCustomerInput;
}>;


export type SetCustomerMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'UnitPrintListSetCustomerPayload' }
    & { unitPrintList?: Maybe<(
      { __typename?: 'UnitPrintList' }
      & Pick<UnitPrintList, 'id'>
      & { customer: (
        { __typename?: 'UnitCustomer' }
        & Pick<UnitCustomer, 'id'>
      ), printJobs: Array<(
        { __typename?: 'ProductLabelPrintJob' }
        & Pick<ProductLabelPrintJob, 'id'>
      )> }
    )>, userErrors: Array<(
      { __typename: 'UnitPrintListSetCustomerNotFoundError' }
      & Pick<UnitPrintListSetCustomerNotFoundError, 'message'>
    ) | (
      { __typename: 'UnitPrintListSetCustomerCustomerNotFoundError' }
      & Pick<UnitPrintListSetCustomerCustomerNotFoundError, 'message'>
    )> }
  ) }
);

export type SetUnitNumberMutationVariables = Exact<{
  input: UnitPrintListSetUnitNumberInput;
}>;


export type SetUnitNumberMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'UnitPrintListSetUnitNumberPayload' }
    & { unitPrintList?: Maybe<(
      { __typename?: 'UnitPrintList' }
      & Pick<UnitPrintList, 'id' | 'unitNumber'>
    )>, userErrors: Array<(
      { __typename: 'UnitPrintListSetUnitNumberNotFoundError' }
      & Pick<UnitPrintListSetUnitNumberNotFoundError, 'message'>
    ) | (
      { __typename: 'UnitPrintListSetUnitNumberInvalidUnitNumberError' }
      & Pick<UnitPrintListSetUnitNumberInvalidUnitNumberError, 'message'>
    )> }
  ) }
);

export type SetDateDueMutationVariables = Exact<{
  input: UnitPrintListSetDateDueInput;
}>;


export type SetDateDueMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'UnitPrintListSetDateDuePayload' }
    & { unitPrintList?: Maybe<(
      { __typename?: 'UnitPrintList' }
      & Pick<UnitPrintList, 'id' | 'dateDue'>
    )>, userErrors: Array<(
      { __typename: 'UnitPrintListSetDateDueNotFoundError' }
      & Pick<UnitPrintListSetDateDueNotFoundError, 'message'>
    )> }
  ) }
);

export type SetManufactureDateCodeMutationVariables = Exact<{
  input: UnitPrintListSetManufactureDateCodeInput;
}>;


export type SetManufactureDateCodeMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'UnitPrintListSetManufactureDateCodePayload' }
    & { unitPrintList?: Maybe<(
      { __typename?: 'UnitPrintList' }
      & Pick<UnitPrintList, 'id' | 'manufactureDateCode'>
    )>, userErrors: Array<(
      { __typename: 'UnitPrintListSetManufactureDateCodeNotFoundError' }
      & Pick<UnitPrintListSetManufactureDateCodeNotFoundError, 'message'>
    )> }
  ) }
);

export type PrintJobMutationVariables = Exact<{
  input: ProductLabelPrintJobPrintInput;
}>;


export type PrintJobMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'ProductLabelPrintJobPrintPayload' }
    & { printJob?: Maybe<(
      { __typename?: 'ProductLabelPrintJob' }
      & Pick<ProductLabelPrintJob, 'id' | 'labelsRemaining' | 'isPrinting'>
    )>, userErrors: Array<(
      { __typename: 'ProductLabelPrintJobPrintNotFoundError' }
      & Pick<ProductLabelPrintJobPrintNotFoundError, 'message'>
    ) | (
      { __typename: 'ProductLabelPrintJobPrintInvalidQuantityError' }
      & Pick<ProductLabelPrintJobPrintInvalidQuantityError, 'message'>
    )> }
  ) }
);

export type PrintJobConfirmMutationVariables = Exact<{
  input: ProductLabelPrintJobConfirmPrintedInput;
}>;


export type PrintJobConfirmMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'ProductLabelPrintJobConfirmPrintedPayload' }
    & { printJob?: Maybe<(
      { __typename?: 'ProductLabelPrintJob' }
      & Pick<ProductLabelPrintJob, 'id' | 'labelsRemaining' | 'isPrinting'>
    )>, userErrors: Array<(
      { __typename: 'ProductLabelPrintJobConfirmPrintedNotFoundError' }
      & Pick<ProductLabelPrintJobConfirmPrintedNotFoundError, 'message'>
    ) | (
      { __typename: 'ProductLabelPrintJobConfirmPrintedNotPrintingError' }
      & Pick<ProductLabelPrintJobConfirmPrintedNotPrintingError, 'message'>
    ) | (
      { __typename: 'ProductLabelPrintJobConfirmPrintedInvalidQuantityError' }
      & Pick<ProductLabelPrintJobConfirmPrintedInvalidQuantityError, 'message'>
    )> }
  ) }
);

export type PrintJobCompleteMutationVariables = Exact<{
  input: ProductLabelPrintJobCompleteInput;
}>;


export type PrintJobCompleteMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'ProductLabelPrintJobCompletePayload' }
    & { printJob?: Maybe<(
      { __typename?: 'ProductLabelPrintJob' }
      & Pick<ProductLabelPrintJob, 'id' | 'labelsRemaining' | 'isPrinting'>
    )>, userErrors: Array<(
      { __typename: 'ProductLabelPrintJobCompleteNotFoundError' }
      & Pick<ProductLabelPrintJobCompleteNotFoundError, 'message'>
    )> }
  ) }
);

export type PrintCustomMutationVariables = Exact<{
  input: ProductLabelPrintCustomInput;
}>;


export type PrintCustomMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'ProductLabelPrintCustomPayload' }
    & { userErrors: Array<(
      { __typename: 'ProductLabelPrintCustomLabelTypeNotFoundError' }
      & Pick<ProductLabelPrintCustomLabelTypeNotFoundError, 'message'>
    ) | (
      { __typename: 'ProductLabelPrintCustomInvalidDataError' }
      & Pick<ProductLabelPrintCustomInvalidDataError, 'message'>
    )> }
  ) }
);


export const UnitPrintListsDocument = gql`
    query UnitPrintLists($statuses: [UnitPrintListStatus!]!, $take: Int) {
  unitPrintLists(statuses: $statuses, take: $take) {
    id
    status
    customer {
      id
      name
    }
    unitNumber
    dateDue
  }
}
    `;

/**
 * __useUnitPrintListsQuery__
 *
 * To run a query within a React component, call `useUnitPrintListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitPrintListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitPrintListsQuery({
 *   variables: {
 *      statuses: // value for 'statuses'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useUnitPrintListsQuery(baseOptions: Apollo.QueryHookOptions<UnitPrintListsQuery, UnitPrintListsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnitPrintListsQuery, UnitPrintListsQueryVariables>(UnitPrintListsDocument, options);
      }
export function useUnitPrintListsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnitPrintListsQuery, UnitPrintListsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnitPrintListsQuery, UnitPrintListsQueryVariables>(UnitPrintListsDocument, options);
        }
export type UnitPrintListsQueryHookResult = ReturnType<typeof useUnitPrintListsQuery>;
export type UnitPrintListsLazyQueryHookResult = ReturnType<typeof useUnitPrintListsLazyQuery>;
export type UnitPrintListsQueryResult = Apollo.QueryResult<UnitPrintListsQuery, UnitPrintListsQueryVariables>;
export const UnitPrintListDocument = gql`
    query UnitPrintList($id: ID!) {
  unitPrintList(id: $id) {
    id
    status
    unitNumber
    dateDue
    manufactureDateCode
    customer {
      id
    }
    printJobs {
      id
      barcode
      productCode
      isPrinting
      labelsRemaining
      labelsRequired
      labelType {
        id
        name
        maxRollQuantity
      }
    }
  }
}
    `;

/**
 * __useUnitPrintListQuery__
 *
 * To run a query within a React component, call `useUnitPrintListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitPrintListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitPrintListQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnitPrintListQuery(baseOptions: Apollo.QueryHookOptions<UnitPrintListQuery, UnitPrintListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnitPrintListQuery, UnitPrintListQueryVariables>(UnitPrintListDocument, options);
      }
export function useUnitPrintListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnitPrintListQuery, UnitPrintListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnitPrintListQuery, UnitPrintListQueryVariables>(UnitPrintListDocument, options);
        }
export type UnitPrintListQueryHookResult = ReturnType<typeof useUnitPrintListQuery>;
export type UnitPrintListLazyQueryHookResult = ReturnType<typeof useUnitPrintListLazyQuery>;
export type UnitPrintListQueryResult = Apollo.QueryResult<UnitPrintListQuery, UnitPrintListQueryVariables>;
export const CustomerIdsDocument = gql`
    query CustomerIds {
  unitCustomers {
    id
  }
}
    `;

/**
 * __useCustomerIdsQuery__
 *
 * To run a query within a React component, call `useCustomerIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerIdsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerIdsQuery(baseOptions?: Apollo.QueryHookOptions<CustomerIdsQuery, CustomerIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerIdsQuery, CustomerIdsQueryVariables>(CustomerIdsDocument, options);
      }
export function useCustomerIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerIdsQuery, CustomerIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerIdsQuery, CustomerIdsQueryVariables>(CustomerIdsDocument, options);
        }
export type CustomerIdsQueryHookResult = ReturnType<typeof useCustomerIdsQuery>;
export type CustomerIdsLazyQueryHookResult = ReturnType<typeof useCustomerIdsLazyQuery>;
export type CustomerIdsQueryResult = Apollo.QueryResult<CustomerIdsQuery, CustomerIdsQueryVariables>;
export const CustomersDocument = gql`
    query Customers {
  unitCustomers {
    id
    name
  }
}
    `;

/**
 * __useCustomersQuery__
 *
 * To run a query within a React component, call `useCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomersQuery(baseOptions?: Apollo.QueryHookOptions<CustomersQuery, CustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomersQuery, CustomersQueryVariables>(CustomersDocument, options);
      }
export function useCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomersQuery, CustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomersQuery, CustomersQueryVariables>(CustomersDocument, options);
        }
export type CustomersQueryHookResult = ReturnType<typeof useCustomersQuery>;
export type CustomersLazyQueryHookResult = ReturnType<typeof useCustomersLazyQuery>;
export type CustomersQueryResult = Apollo.QueryResult<CustomersQuery, CustomersQueryVariables>;
export const FullCustomersDocument = gql`
    query FullCustomers {
  unitCustomers {
    id
    name
    labelTypes {
      id
      name
      maxRollQuantity
      customFields {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useFullCustomersQuery__
 *
 * To run a query within a React component, call `useFullCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFullCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFullCustomersQuery({
 *   variables: {
 *   },
 * });
 */
export function useFullCustomersQuery(baseOptions?: Apollo.QueryHookOptions<FullCustomersQuery, FullCustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FullCustomersQuery, FullCustomersQueryVariables>(FullCustomersDocument, options);
      }
export function useFullCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FullCustomersQuery, FullCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FullCustomersQuery, FullCustomersQueryVariables>(FullCustomersDocument, options);
        }
export type FullCustomersQueryHookResult = ReturnType<typeof useFullCustomersQuery>;
export type FullCustomersLazyQueryHookResult = ReturnType<typeof useFullCustomersLazyQuery>;
export type FullCustomersQueryResult = Apollo.QueryResult<FullCustomersQuery, FullCustomersQueryVariables>;
export const PrintersDocument = gql`
    query Printers {
  printers {
    id
  }
}
    `;

/**
 * __usePrintersQuery__
 *
 * To run a query within a React component, call `usePrintersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrintersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrintersQuery({
 *   variables: {
 *   },
 * });
 */
export function usePrintersQuery(baseOptions?: Apollo.QueryHookOptions<PrintersQuery, PrintersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PrintersQuery, PrintersQueryVariables>(PrintersDocument, options);
      }
export function usePrintersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PrintersQuery, PrintersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PrintersQuery, PrintersQueryVariables>(PrintersDocument, options);
        }
export type PrintersQueryHookResult = ReturnType<typeof usePrintersQuery>;
export type PrintersLazyQueryHookResult = ReturnType<typeof usePrintersLazyQuery>;
export type PrintersQueryResult = Apollo.QueryResult<PrintersQuery, PrintersQueryVariables>;
export const CreateUnitPrintListDocument = gql`
    mutation CreateUnitPrintList($input: UnitPrintListCreateInput!) {
  result: unitPrintListCreate(input: $input) {
    unitPrintList {
      id
      status
      dateCreated
      dateDue
      manufactureDateCode
      unitNumber
      customer {
        id
      }
      printJobs {
        id
      }
    }
    userErrors {
      ... on UserError {
        __typename
        message
      }
    }
  }
}
    `;
export type CreateUnitPrintListMutationFn = Apollo.MutationFunction<CreateUnitPrintListMutation, CreateUnitPrintListMutationVariables>;

/**
 * __useCreateUnitPrintListMutation__
 *
 * To run a mutation, you first call `useCreateUnitPrintListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUnitPrintListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUnitPrintListMutation, { data, loading, error }] = useCreateUnitPrintListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUnitPrintListMutation(baseOptions?: Apollo.MutationHookOptions<CreateUnitPrintListMutation, CreateUnitPrintListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUnitPrintListMutation, CreateUnitPrintListMutationVariables>(CreateUnitPrintListDocument, options);
      }
export type CreateUnitPrintListMutationHookResult = ReturnType<typeof useCreateUnitPrintListMutation>;
export type CreateUnitPrintListMutationResult = Apollo.MutationResult<CreateUnitPrintListMutation>;
export type CreateUnitPrintListMutationOptions = Apollo.BaseMutationOptions<CreateUnitPrintListMutation, CreateUnitPrintListMutationVariables>;
export const PrintSummaryDocument = gql`
    mutation PrintSummary($input: UnitPrintListPrintSummaryInput!) {
  result: unitPrintListPrintSummary(input: $input) {
    userErrors {
      ... on UserError {
        __typename
        message
      }
    }
  }
}
    `;
export type PrintSummaryMutationFn = Apollo.MutationFunction<PrintSummaryMutation, PrintSummaryMutationVariables>;

/**
 * __usePrintSummaryMutation__
 *
 * To run a mutation, you first call `usePrintSummaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrintSummaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [printSummaryMutation, { data, loading, error }] = usePrintSummaryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePrintSummaryMutation(baseOptions?: Apollo.MutationHookOptions<PrintSummaryMutation, PrintSummaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PrintSummaryMutation, PrintSummaryMutationVariables>(PrintSummaryDocument, options);
      }
export type PrintSummaryMutationHookResult = ReturnType<typeof usePrintSummaryMutation>;
export type PrintSummaryMutationResult = Apollo.MutationResult<PrintSummaryMutation>;
export type PrintSummaryMutationOptions = Apollo.BaseMutationOptions<PrintSummaryMutation, PrintSummaryMutationVariables>;
export const MarkReadyDocument = gql`
    mutation MarkReady($input: UnitPrintListMarkReadyInput!) {
  result: unitPrintListMarkReady(input: $input) {
    unitPrintList {
      id
      status
    }
    userErrors {
      ... on UserError {
        __typename
        message
      }
    }
  }
}
    `;
export type MarkReadyMutationFn = Apollo.MutationFunction<MarkReadyMutation, MarkReadyMutationVariables>;

/**
 * __useMarkReadyMutation__
 *
 * To run a mutation, you first call `useMarkReadyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkReadyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markReadyMutation, { data, loading, error }] = useMarkReadyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkReadyMutation(baseOptions?: Apollo.MutationHookOptions<MarkReadyMutation, MarkReadyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkReadyMutation, MarkReadyMutationVariables>(MarkReadyDocument, options);
      }
export type MarkReadyMutationHookResult = ReturnType<typeof useMarkReadyMutation>;
export type MarkReadyMutationResult = Apollo.MutationResult<MarkReadyMutation>;
export type MarkReadyMutationOptions = Apollo.BaseMutationOptions<MarkReadyMutation, MarkReadyMutationVariables>;
export const MarkDraftDocument = gql`
    mutation MarkDraft($input: UnitPrintListMarkDraftInput!) {
  result: unitPrintListMarkDraft(input: $input) {
    unitPrintList {
      id
      status
    }
    userErrors {
      ... on UserError {
        __typename
        message
      }
    }
  }
}
    `;
export type MarkDraftMutationFn = Apollo.MutationFunction<MarkDraftMutation, MarkDraftMutationVariables>;

/**
 * __useMarkDraftMutation__
 *
 * To run a mutation, you first call `useMarkDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markDraftMutation, { data, loading, error }] = useMarkDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkDraftMutation(baseOptions?: Apollo.MutationHookOptions<MarkDraftMutation, MarkDraftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkDraftMutation, MarkDraftMutationVariables>(MarkDraftDocument, options);
      }
export type MarkDraftMutationHookResult = ReturnType<typeof useMarkDraftMutation>;
export type MarkDraftMutationResult = Apollo.MutationResult<MarkDraftMutation>;
export type MarkDraftMutationOptions = Apollo.BaseMutationOptions<MarkDraftMutation, MarkDraftMutationVariables>;
export const DeleteUnitPrintListDocument = gql`
    mutation DeleteUnitPrintList($input: UnitPrintListDeleteInput!) {
  result: unitPrintListDelete(input: $input) {
    userErrors {
      ... on UserError {
        __typename
        message
      }
    }
  }
}
    `;
export type DeleteUnitPrintListMutationFn = Apollo.MutationFunction<DeleteUnitPrintListMutation, DeleteUnitPrintListMutationVariables>;

/**
 * __useDeleteUnitPrintListMutation__
 *
 * To run a mutation, you first call `useDeleteUnitPrintListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUnitPrintListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUnitPrintListMutation, { data, loading, error }] = useDeleteUnitPrintListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUnitPrintListMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUnitPrintListMutation, DeleteUnitPrintListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUnitPrintListMutation, DeleteUnitPrintListMutationVariables>(DeleteUnitPrintListDocument, options);
      }
export type DeleteUnitPrintListMutationHookResult = ReturnType<typeof useDeleteUnitPrintListMutation>;
export type DeleteUnitPrintListMutationResult = Apollo.MutationResult<DeleteUnitPrintListMutation>;
export type DeleteUnitPrintListMutationOptions = Apollo.BaseMutationOptions<DeleteUnitPrintListMutation, DeleteUnitPrintListMutationVariables>;
export const UploadJobsDocument = gql`
    mutation UploadJobs($input: UnitPrintListUploadJobsInput!) {
  result: unitPrintListUploadJobs(input: $input) {
    unitPrintList {
      id
      printJobs {
        id
        barcode
        productCode
        isPrinting
        labelsRemaining
        labelsRequired
        labelType {
          id
          name
          maxRollQuantity
        }
      }
    }
    userErrors {
      ... on UserError {
        __typename
        message
      }
    }
  }
}
    `;
export type UploadJobsMutationFn = Apollo.MutationFunction<UploadJobsMutation, UploadJobsMutationVariables>;

/**
 * __useUploadJobsMutation__
 *
 * To run a mutation, you first call `useUploadJobsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadJobsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadJobsMutation, { data, loading, error }] = useUploadJobsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadJobsMutation(baseOptions?: Apollo.MutationHookOptions<UploadJobsMutation, UploadJobsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadJobsMutation, UploadJobsMutationVariables>(UploadJobsDocument, options);
      }
export type UploadJobsMutationHookResult = ReturnType<typeof useUploadJobsMutation>;
export type UploadJobsMutationResult = Apollo.MutationResult<UploadJobsMutation>;
export type UploadJobsMutationOptions = Apollo.BaseMutationOptions<UploadJobsMutation, UploadJobsMutationVariables>;
export const CompleteUnitPrintListDocument = gql`
    mutation CompleteUnitPrintList($input: UnitPrintListCompleteInput!) {
  result: unitPrintListComplete(input: $input) {
    unitPrintList {
      id
      status
    }
    userErrors {
      ... on UserError {
        __typename
        message
      }
    }
  }
}
    `;
export type CompleteUnitPrintListMutationFn = Apollo.MutationFunction<CompleteUnitPrintListMutation, CompleteUnitPrintListMutationVariables>;

/**
 * __useCompleteUnitPrintListMutation__
 *
 * To run a mutation, you first call `useCompleteUnitPrintListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteUnitPrintListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeUnitPrintListMutation, { data, loading, error }] = useCompleteUnitPrintListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteUnitPrintListMutation(baseOptions?: Apollo.MutationHookOptions<CompleteUnitPrintListMutation, CompleteUnitPrintListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteUnitPrintListMutation, CompleteUnitPrintListMutationVariables>(CompleteUnitPrintListDocument, options);
      }
export type CompleteUnitPrintListMutationHookResult = ReturnType<typeof useCompleteUnitPrintListMutation>;
export type CompleteUnitPrintListMutationResult = Apollo.MutationResult<CompleteUnitPrintListMutation>;
export type CompleteUnitPrintListMutationOptions = Apollo.BaseMutationOptions<CompleteUnitPrintListMutation, CompleteUnitPrintListMutationVariables>;
export const SetCustomerDocument = gql`
    mutation SetCustomer($input: UnitPrintListSetCustomerInput!) {
  result: unitPrintListSetCustomer(input: $input) {
    unitPrintList {
      id
      customer {
        id
      }
      printJobs {
        id
      }
    }
    userErrors {
      ... on UserError {
        __typename
        message
      }
    }
  }
}
    `;
export type SetCustomerMutationFn = Apollo.MutationFunction<SetCustomerMutation, SetCustomerMutationVariables>;

/**
 * __useSetCustomerMutation__
 *
 * To run a mutation, you first call `useSetCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCustomerMutation, { data, loading, error }] = useSetCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetCustomerMutation(baseOptions?: Apollo.MutationHookOptions<SetCustomerMutation, SetCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCustomerMutation, SetCustomerMutationVariables>(SetCustomerDocument, options);
      }
export type SetCustomerMutationHookResult = ReturnType<typeof useSetCustomerMutation>;
export type SetCustomerMutationResult = Apollo.MutationResult<SetCustomerMutation>;
export type SetCustomerMutationOptions = Apollo.BaseMutationOptions<SetCustomerMutation, SetCustomerMutationVariables>;
export const SetUnitNumberDocument = gql`
    mutation SetUnitNumber($input: UnitPrintListSetUnitNumberInput!) {
  result: unitPrintListSetUnitNumber(input: $input) {
    unitPrintList {
      id
      unitNumber
    }
    userErrors {
      ... on UserError {
        __typename
        message
      }
    }
  }
}
    `;
export type SetUnitNumberMutationFn = Apollo.MutationFunction<SetUnitNumberMutation, SetUnitNumberMutationVariables>;

/**
 * __useSetUnitNumberMutation__
 *
 * To run a mutation, you first call `useSetUnitNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUnitNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUnitNumberMutation, { data, loading, error }] = useSetUnitNumberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetUnitNumberMutation(baseOptions?: Apollo.MutationHookOptions<SetUnitNumberMutation, SetUnitNumberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUnitNumberMutation, SetUnitNumberMutationVariables>(SetUnitNumberDocument, options);
      }
export type SetUnitNumberMutationHookResult = ReturnType<typeof useSetUnitNumberMutation>;
export type SetUnitNumberMutationResult = Apollo.MutationResult<SetUnitNumberMutation>;
export type SetUnitNumberMutationOptions = Apollo.BaseMutationOptions<SetUnitNumberMutation, SetUnitNumberMutationVariables>;
export const SetDateDueDocument = gql`
    mutation SetDateDue($input: UnitPrintListSetDateDueInput!) {
  result: unitPrintListSetDateDue(input: $input) {
    unitPrintList {
      id
      dateDue
    }
    userErrors {
      ... on UserError {
        __typename
        message
      }
    }
  }
}
    `;
export type SetDateDueMutationFn = Apollo.MutationFunction<SetDateDueMutation, SetDateDueMutationVariables>;

/**
 * __useSetDateDueMutation__
 *
 * To run a mutation, you first call `useSetDateDueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDateDueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDateDueMutation, { data, loading, error }] = useSetDateDueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetDateDueMutation(baseOptions?: Apollo.MutationHookOptions<SetDateDueMutation, SetDateDueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetDateDueMutation, SetDateDueMutationVariables>(SetDateDueDocument, options);
      }
export type SetDateDueMutationHookResult = ReturnType<typeof useSetDateDueMutation>;
export type SetDateDueMutationResult = Apollo.MutationResult<SetDateDueMutation>;
export type SetDateDueMutationOptions = Apollo.BaseMutationOptions<SetDateDueMutation, SetDateDueMutationVariables>;
export const SetManufactureDateCodeDocument = gql`
    mutation SetManufactureDateCode($input: UnitPrintListSetManufactureDateCodeInput!) {
  result: unitPrintListSetManufactureDateCode(input: $input) {
    unitPrintList {
      id
      manufactureDateCode
    }
    userErrors {
      ... on UserError {
        __typename
        message
      }
    }
  }
}
    `;
export type SetManufactureDateCodeMutationFn = Apollo.MutationFunction<SetManufactureDateCodeMutation, SetManufactureDateCodeMutationVariables>;

/**
 * __useSetManufactureDateCodeMutation__
 *
 * To run a mutation, you first call `useSetManufactureDateCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetManufactureDateCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setManufactureDateCodeMutation, { data, loading, error }] = useSetManufactureDateCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetManufactureDateCodeMutation(baseOptions?: Apollo.MutationHookOptions<SetManufactureDateCodeMutation, SetManufactureDateCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetManufactureDateCodeMutation, SetManufactureDateCodeMutationVariables>(SetManufactureDateCodeDocument, options);
      }
export type SetManufactureDateCodeMutationHookResult = ReturnType<typeof useSetManufactureDateCodeMutation>;
export type SetManufactureDateCodeMutationResult = Apollo.MutationResult<SetManufactureDateCodeMutation>;
export type SetManufactureDateCodeMutationOptions = Apollo.BaseMutationOptions<SetManufactureDateCodeMutation, SetManufactureDateCodeMutationVariables>;
export const PrintJobDocument = gql`
    mutation PrintJob($input: ProductLabelPrintJobPrintInput!) {
  result: productLabelPrintJobPrint(input: $input) {
    printJob {
      id
      labelsRemaining
      isPrinting
    }
    userErrors {
      ... on UserError {
        __typename
        message
      }
    }
  }
}
    `;
export type PrintJobMutationFn = Apollo.MutationFunction<PrintJobMutation, PrintJobMutationVariables>;

/**
 * __usePrintJobMutation__
 *
 * To run a mutation, you first call `usePrintJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrintJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [printJobMutation, { data, loading, error }] = usePrintJobMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePrintJobMutation(baseOptions?: Apollo.MutationHookOptions<PrintJobMutation, PrintJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PrintJobMutation, PrintJobMutationVariables>(PrintJobDocument, options);
      }
export type PrintJobMutationHookResult = ReturnType<typeof usePrintJobMutation>;
export type PrintJobMutationResult = Apollo.MutationResult<PrintJobMutation>;
export type PrintJobMutationOptions = Apollo.BaseMutationOptions<PrintJobMutation, PrintJobMutationVariables>;
export const PrintJobConfirmDocument = gql`
    mutation PrintJobConfirm($input: ProductLabelPrintJobConfirmPrintedInput!) {
  result: productLabelPrintJobConfirmPrinted(input: $input) {
    printJob {
      id
      labelsRemaining
      isPrinting
    }
    userErrors {
      ... on UserError {
        __typename
        message
      }
    }
  }
}
    `;
export type PrintJobConfirmMutationFn = Apollo.MutationFunction<PrintJobConfirmMutation, PrintJobConfirmMutationVariables>;

/**
 * __usePrintJobConfirmMutation__
 *
 * To run a mutation, you first call `usePrintJobConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrintJobConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [printJobConfirmMutation, { data, loading, error }] = usePrintJobConfirmMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePrintJobConfirmMutation(baseOptions?: Apollo.MutationHookOptions<PrintJobConfirmMutation, PrintJobConfirmMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PrintJobConfirmMutation, PrintJobConfirmMutationVariables>(PrintJobConfirmDocument, options);
      }
export type PrintJobConfirmMutationHookResult = ReturnType<typeof usePrintJobConfirmMutation>;
export type PrintJobConfirmMutationResult = Apollo.MutationResult<PrintJobConfirmMutation>;
export type PrintJobConfirmMutationOptions = Apollo.BaseMutationOptions<PrintJobConfirmMutation, PrintJobConfirmMutationVariables>;
export const PrintJobCompleteDocument = gql`
    mutation PrintJobComplete($input: ProductLabelPrintJobCompleteInput!) {
  result: productLabelPrintJobComplete(input: $input) {
    printJob {
      id
      labelsRemaining
      isPrinting
    }
    userErrors {
      ... on UserError {
        __typename
        message
      }
    }
  }
}
    `;
export type PrintJobCompleteMutationFn = Apollo.MutationFunction<PrintJobCompleteMutation, PrintJobCompleteMutationVariables>;

/**
 * __usePrintJobCompleteMutation__
 *
 * To run a mutation, you first call `usePrintJobCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrintJobCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [printJobCompleteMutation, { data, loading, error }] = usePrintJobCompleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePrintJobCompleteMutation(baseOptions?: Apollo.MutationHookOptions<PrintJobCompleteMutation, PrintJobCompleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PrintJobCompleteMutation, PrintJobCompleteMutationVariables>(PrintJobCompleteDocument, options);
      }
export type PrintJobCompleteMutationHookResult = ReturnType<typeof usePrintJobCompleteMutation>;
export type PrintJobCompleteMutationResult = Apollo.MutationResult<PrintJobCompleteMutation>;
export type PrintJobCompleteMutationOptions = Apollo.BaseMutationOptions<PrintJobCompleteMutation, PrintJobCompleteMutationVariables>;
export const PrintCustomDocument = gql`
    mutation PrintCustom($input: ProductLabelPrintCustomInput!) {
  result: productLabelPrintCustom(input: $input) {
    userErrors {
      ... on UserError {
        __typename
        message
      }
    }
  }
}
    `;
export type PrintCustomMutationFn = Apollo.MutationFunction<PrintCustomMutation, PrintCustomMutationVariables>;

/**
 * __usePrintCustomMutation__
 *
 * To run a mutation, you first call `usePrintCustomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrintCustomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [printCustomMutation, { data, loading, error }] = usePrintCustomMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePrintCustomMutation(baseOptions?: Apollo.MutationHookOptions<PrintCustomMutation, PrintCustomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PrintCustomMutation, PrintCustomMutationVariables>(PrintCustomDocument, options);
      }
export type PrintCustomMutationHookResult = ReturnType<typeof usePrintCustomMutation>;
export type PrintCustomMutationResult = Apollo.MutationResult<PrintCustomMutation>;
export type PrintCustomMutationOptions = Apollo.BaseMutationOptions<PrintCustomMutation, PrintCustomMutationVariables>;