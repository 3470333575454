import {
  Button,
  Container,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
} from '@mui/material';
import {
  UnitPrintListQuery,
  UnitPrintListStatus,
  usePrintJobCompleteMutation,
  usePrintJobConfirmMutation,
  usePrintJobMutation,
  useUnitPrintListQuery,
} from '../generated/graphql';
import { Loading } from './Loading';
import { Error } from './Error';
import { displayUserErrors, getPrintBatchQuantity } from '../util';
import { INDIVIDUAL_POLL_INTERVAL_MS } from '../constants';
import { useState } from 'react';

export interface PrintJobsTableProps {
  selectedUnitPrintListId: string;
}

const PrintJobsTable: React.FC<PrintJobsTableProps> = ({
  selectedUnitPrintListId,
}) => {
  const { data, loading, error } = useUnitPrintListQuery({
    variables: { id: selectedUnitPrintListId },
    pollInterval: INDIVIDUAL_POLL_INTERVAL_MS,
  });

  if (loading) return <Loading />;
  if (error || !data?.unitPrintList) return <Error />;

  const completed = data.unitPrintList.status === UnitPrintListStatus.Completed;
  return (
    <Container style={{ paddingLeft: 0, paddingRight: 0 }}>
      {completed && (
        <Typography variant="h6" style={{ padding: '0em 0 4em 0' }}>
          This list has been completed
        </Typography>
      )}
      <Table stickyHeader>
        <TableHead>
          <TableCell>ID</TableCell>
          <TableCell>Barcode</TableCell>
          <TableCell>Product Code</TableCell>
          <TableCell>Remaining</TableCell>
          <TableCell>Type</TableCell>
          <TableCell style={{ width: 330 }} />
        </TableHead>
        <TableBody>
          {data.unitPrintList.printJobs.map((job) => (
            <PrintJobRow key={job.id} job={job} completed={completed} />
          ))}
        </TableBody>
      </Table>
    </Container>
  );
};

type List = NonNullable<UnitPrintListQuery['unitPrintList']>;
type Job = List['printJobs'][0];

const PrintJobRow: React.FC<{ job: Job; completed: boolean }> = ({
  job,
  completed,
}) => {
  const rowDone = job.labelsRemaining === 0;
  const batchQuantity = getPrintBatchQuantity(
    job.labelsRemaining,
    job.labelType.maxRollQuantity,
  );

  const style = rowDone && !completed ? { opacity: 0.3 } : undefined;
  return (
    <TableRow>
      <TableCell style={{ fontSize: '1rem' }}>
        <span style={style}>{job.id}</span>
      </TableCell>
      <TableCell style={{ fontSize: '0.8rem' }}>
        <span style={style}>{job.barcode}</span>
      </TableCell>
      <TableCell style={{ fontSize: '0.8rem' }}>
        <span style={style}>{job.productCode}</span>
      </TableCell>
      <TableCell style={{ fontSize: '1.5rem' }}>
        <span style={style}>
          {job.labelsRemaining}/{job.labelsRequired}
        </span>
      </TableCell>
      <TableCell style={{ fontSize: '1.5rem' }}>
        <span style={style}>{job.labelType.name}</span>
      </TableCell>
      {rowDone || completed ? (
        <PrintExtra printJobId={job.id} />
      ) : job.isPrinting ? (
        <BatchPrintDisplay printJobId={job.id} batchQuantity={batchQuantity} />
      ) : (
        <StartPrintBatchDisplay
          printJobId={job.id}
          batchQuantity={batchQuantity}
        />
      )}
    </TableRow>
  );
};

const PrintExtra: React.FC<{
  printJobId: string;
}> = ({ printJobId }) => {
  const [quantity, setQuantity] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const printExtra = usePrintJob(printJobId, parseInt(quantity, 10), true);

  return (
    <TableCell align="right">
      <Tooltip title="Reprint this batch">
        <Button onClick={() => setModalOpen(true)} variant="outlined">
          Print Extra
        </Button>
      </Tooltip>
      <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
        <DialogTitle>Print Extra Labels</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Choose how many extra labels you would like to print.
          </DialogContentText>
          <TextField
            variant="standard"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalOpen(false)}>Cancel</Button>
          <Button
            disabled={isNaN(parseInt(quantity, 10))}
            onClick={() => {
              setModalOpen(false);
              printExtra();
            }}
          >
            Print
          </Button>
        </DialogActions>
      </Dialog>
    </TableCell>
  );
};
const BatchPrintDisplay: React.FC<{
  printJobId: string;
  batchQuantity: number;
}> = ({ printJobId, batchQuantity }) => {
  const print = usePrintJob(printJobId, batchQuantity, false);
  const accept = usePrintJobConfirm(printJobId, batchQuantity);

  return (
    <TableCell>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          style={{ fontSize: '1.7rem', color: 'rgba(105, 171, 74, 0.45)' }}
        >
          -{batchQuantity}
        </Typography>
        <Tooltip title="Reprint this batch">
          <Button onClick={print} variant="outlined">
            Reprint
          </Button>
        </Tooltip>
        <Tooltip title="Mark batch as completed">
          <Button onClick={accept} variant="outlined">
            Accept
          </Button>
        </Tooltip>
      </div>
    </TableCell>
  );
};

const StartPrintBatchDisplay: React.FC<{
  printJobId: string;
  batchQuantity: number;
}> = ({ printJobId, batchQuantity }) => {
  const print = usePrintJob(printJobId, batchQuantity, false);
  const skip = usePrintJobComplete(printJobId);

  return (
    <TableCell>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Tooltip title="Start printing the batch">
          <Button
            style={{ flexGrow: 1, marginRight: 10 }}
            onClick={print}
            variant="outlined"
          >
            Start ({batchQuantity})
          </Button>
        </Tooltip>
        <Tooltip title="Skip printing this job">
          <Button style={{ width: 50 }} onClick={skip} variant="outlined">
            Skip
          </Button>
        </Tooltip>
      </div>
    </TableCell>
  );
};

function usePrintJob(
  printJobId: string,
  batchQuantity: number,
  extra: boolean,
) {
  const [print] = usePrintJobMutation({
    variables: {
      input: {
        printJobId,
        printQuantity: batchQuantity,
        extra,
      },
    },
  });

  return () => displayUserErrors('result', print(), true);
}

function usePrintJobConfirm(printJobId: string, batchQuantity: number) {
  const [confirm] = usePrintJobConfirmMutation({
    variables: {
      input: {
        printJobId,
        printedQuantity: batchQuantity,
      },
    },
  });

  return () => displayUserErrors('result', confirm(), true);
}

function usePrintJobComplete(printJobId: string) {
  const [complete] = usePrintJobCompleteMutation({
    variables: {
      input: {
        printJobId,
      },
    },
  });

  return () => displayUserErrors('result', complete(), true);
}

export default PrintJobsTable;
