import React from 'react';
import * as Sentry from '@sentry/react';
import { useApolloClient } from '@apollo/client';
import {
  Button,
  Divider,
  Drawer,
  LinearProgress,
  List,
  ListItem,
  Typography,
  Tooltip,
  IconButton,
} from '@mui/material';
import {
  Update as UpdateIcon,
  Print as PrintIcon,
  Done as DoneIcon,
  NotInterested as NotInterestedIcon,
  ChevronLeft as ChevronLeftIcon,
} from '@mui/icons-material';
import styled from 'styled-components';
import { DRAWER_WIDTH, POLL_INTERVAL_MS } from '../constants';
import { displayUserErrors } from '../util';
import {
  CustomerIdsDocument,
  CustomerIdsQuery,
  UnitPrintListsQuery,
  UnitPrintListStatus,
  useCreateUnitPrintListMutation,
  useUnitPrintListsQuery,
} from '../generated/graphql';
import { Error } from './Error';
import { useHistory } from 'react-router-dom';

const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 1em;
`;

const StyledDrawer = styled(Drawer)`
  .paper {
    z-index: 30;
    position: fixed;
    top: 64px;
    height: calc(100vh - 64px);

    width: 100%;
    @media (min-width: 600px) {
      width: ${DRAWER_WIDTH}px;
    }
  }

  .list {
    overflow-y: auto;
    flex-grow: 1;
  }

  .item {
    display: flex;
    align-items: center;
    height: 72px;
  }

  .icon {
    width: 40px;
    display: flex;
    flex-direction: column;
    place-items: center;
    margin-right: 2em;
  }

  .text {
    flex: 1 1;
    padding: 0 1em 0 1.5em;
    overflow: hidden;

    p {
      font-size: 16px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .date {
    flex: 0 0 85px;
    font-size: 16px;
    text-align: end;
  }

  * {
    white-space: nowrap;
  }

  .selected {
    background-color: #eee;
  }
`;

export type ViewType = 'print' | 'manage' | 'completed';

interface SideDrawerProps {
  selectedId: string | null;
  onSelect: (id: string | null) => void;
  viewType: ViewType;
  open: boolean;
  setOpen: (v: boolean) => void;
}

export const SideDrawer: React.FC<SideDrawerProps> = ({
  selectedId,
  onSelect,
  viewType,
  open,
  setOpen,
}) => {
  const history = useHistory();
  const createList = useCreateUnitPrintList();
  const { loading, error, data } = useUnitPrintListsQuery({
    pollInterval: POLL_INTERVAL_MS,
    variables: {
      take: viewType === 'completed' ? 200 : null,
      statuses:
        viewType === 'print'
          ? [UnitPrintListStatus.Ready]
          : viewType === 'completed'
          ? [UnitPrintListStatus.Completed]
          : [UnitPrintListStatus.Ready, UnitPrintListStatus.Draft],
    },
  });

  let contents = null;
  if (loading) contents = <LinearProgress />;
  else if (error || !data) contents = <Error style={{ padding: '2em' }} />;
  else {
    contents = (
      <DrawerList
        elements={data.unitPrintLists}
        onSelect={onSelect}
        selectedId={selectedId}
      />
    );
  }

  const onChangeViewType = (type: ViewType) => {
    if (type === 'print') history.push('/');
    else if (type === 'manage') history.push('/manage');
    else if (type === 'completed') history.push('/completed');
  };

  return (
    <StyledDrawer
      variant="persistent"
      anchor="left"
      open={open}
      classes={{ paper: 'paper' }}
    >
      <DrawerHeader
        style={{
          justifyContent: viewType === 'manage' ? 'space-between' : 'flex-end',
        }}
      >
        {(viewType === 'print' || viewType === 'completed') && (
          <Button
            onClick={() =>
              onChangeViewType(viewType === 'print' ? 'completed' : 'print')
            }
          >
            {viewType === 'print' ? 'Completed' : 'Back to Printing'}
          </Button>
        )}
        {viewType === 'manage' && (
          <Button
            onClick={() =>
              createList()
                .then((x) => {
                  const id = x.data?.result.unitPrintList?.id;
                  if (id == null) return;
                  onSelect(id);
                })
                .catch((error) => {
                  console.error(error);
                  Sentry.captureException(error);
                })
            }
          >
            Create New
          </Button>
        )}
        {(viewType === 'print' || viewType === 'manage') && (
          <Button
            onClick={() => {
              onChangeViewType(viewType === 'manage' ? 'print' : 'manage');
            }}
          >
            {viewType === 'manage' ? 'Dashboard' : 'Manage Units'}
          </Button>
        )}
        <IconButton
          style={{ padding: '12px 6px', marginRight: 6 }}
          onClick={() => setOpen(false)}
          size="large"
        >
          <ChevronLeftIcon />
        </IconButton>
      </DrawerHeader>
      <Divider />
      {contents}
    </StyledDrawer>
  );
};

interface DrawerListProps {
  elements: UnitPrintListsQuery['unitPrintLists'];
  selectedId: string | null;
  onSelect: (id: string | null) => void;
}

const TOOLTIP_MAP: Record<UnitPrintListStatus, string> = {
  DRAFT: 'Draft',
  READY: 'Ready',
  DELETED: 'Deleted',
  COMPLETED: 'Done',
};

const DrawerList: React.FC<DrawerListProps> = ({
  selectedId,
  onSelect,
  elements,
}) => (
  <List className="list">
    {elements.length === 0 && (
      <ListItem style={{ display: 'flex', justifyContent: 'center' }}>
        <Typography>
          Nothing to see here...{' '}
          <span role="img" aria-label="nice">
            👌
          </span>
        </Typography>
      </ListItem>
    )}
    {elements.map((upl) => (
      <ListItem
        button
        key={upl.id}
        onClick={() => onSelect(upl.id === selectedId ? null : upl.id)}
        className={`item ${upl.id === selectedId ? 'selected' : ''}`}
      >
        <Tooltip title={TOOLTIP_MAP[upl.status]}>
          <div className="icon">
            <div>
              {upl.status === UnitPrintListStatus.Draft && <UpdateIcon />}
              {upl.status === UnitPrintListStatus.Ready && <PrintIcon />}
              {upl.status === UnitPrintListStatus.Completed && <DoneIcon />}
              {upl.status === UnitPrintListStatus.Deleted && (
                <NotInterestedIcon />
              )}
            </div>
            <Typography>{TOOLTIP_MAP[upl.status]}</Typography>
          </div>
        </Tooltip>

        <div className="text">
          <Typography>{upl.customer.name}</Typography>
          <Typography>{upl.unitNumber}</Typography>
        </div>

        <div>
          <Typography align="right">DUE</Typography>
          <Typography className="date">{upl.dateDue ?? '-'}</Typography>
        </div>
      </ListItem>
    ))}
  </List>
);

function useCreateUnitPrintList() {
  const apollo = useApolloClient();
  const [create] = useCreateUnitPrintListMutation();

  const go = async () => {
    const { data } = await apollo.query<CustomerIdsQuery>({
      query: CustomerIdsDocument,
    });

    if (!data?.unitCustomers) {
      return { data: null };
    }

    if (data.unitCustomers.length === 0) {
      console.error('No customers configured...');
      return { data: null };
    }

    return await create({
      variables: {
        input: {
          customerId: data.unitCustomers[0].id,
          dateDue: null,
          manufactureDateCode: null,
          unitNumber: null,
        },
      },
      refetchQueries: ['UnitPrintLists'],
      awaitRefetchQueries: true,
    });
  };

  return () => displayUserErrors('result', go(), false);
}
