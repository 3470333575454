
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "LabelTemplateUploadPresetsError": [
      "LabelTemplateUploadPresetsNotFoundError",
      "LabelTemplateUploadPresetsInvalidDataError"
    ],
    "ProductLabelPrintCustomError": [
      "ProductLabelPrintCustomLabelTypeNotFoundError",
      "ProductLabelPrintCustomInvalidDataError"
    ],
    "ProductLabelPrintJobCompleteError": [
      "ProductLabelPrintJobCompleteNotFoundError"
    ],
    "ProductLabelPrintJobConfirmPrintedError": [
      "ProductLabelPrintJobConfirmPrintedNotFoundError",
      "ProductLabelPrintJobConfirmPrintedNotPrintingError",
      "ProductLabelPrintJobConfirmPrintedInvalidQuantityError"
    ],
    "ProductLabelPrintJobPrintError": [
      "ProductLabelPrintJobPrintNotFoundError",
      "ProductLabelPrintJobPrintInvalidQuantityError"
    ],
    "UnitPrintListCompleteError": [
      "UnitPrintListCompleteNotFoundError"
    ],
    "UnitPrintListCreateError": [
      "UnitPrintListCreateCustomerNotFoundError",
      "UnitPrintListCreateInvalidUnitNumberError"
    ],
    "UnitPrintListDeleteError": [
      "UnitPrintListDeleteNotFoundError"
    ],
    "UnitPrintListMarkDraftError": [
      "UnitPrintListMarkDraftNotFoundError"
    ],
    "UnitPrintListMarkReadyError": [
      "UnitPrintListMarkReadyNotFoundError"
    ],
    "UnitPrintListPrintSummaryError": [
      "UnitPrintListPrintSummaryNotFoundError"
    ],
    "UnitPrintListSetCustomerError": [
      "UnitPrintListSetCustomerNotFoundError",
      "UnitPrintListSetCustomerCustomerNotFoundError"
    ],
    "UnitPrintListSetDateDueError": [
      "UnitPrintListSetDateDueNotFoundError"
    ],
    "UnitPrintListSetManufactureDateCodeError": [
      "UnitPrintListSetManufactureDateCodeNotFoundError"
    ],
    "UnitPrintListSetUnitNumberError": [
      "UnitPrintListSetUnitNumberNotFoundError",
      "UnitPrintListSetUnitNumberInvalidUnitNumberError"
    ],
    "UnitPrintListUploadJobsError": [
      "UnitPrintListUploadJobsNotFoundError",
      "UnitPrintListUploadJobsInvalidDataError"
    ],
    "UserError": [
      "LabelTemplateUploadPresetsInvalidDataError",
      "LabelTemplateUploadPresetsNotFoundError",
      "ProductLabelPrintCustomInvalidDataError",
      "ProductLabelPrintCustomLabelTypeNotFoundError",
      "ProductLabelPrintJobCompleteNotFoundError",
      "ProductLabelPrintJobConfirmPrintedInvalidQuantityError",
      "ProductLabelPrintJobConfirmPrintedNotFoundError",
      "ProductLabelPrintJobConfirmPrintedNotPrintingError",
      "ProductLabelPrintJobPrintInvalidQuantityError",
      "ProductLabelPrintJobPrintNotFoundError",
      "UnitPrintListCompleteNotFoundError",
      "UnitPrintListCreateCustomerNotFoundError",
      "UnitPrintListCreateInvalidUnitNumberError",
      "UnitPrintListDeleteNotFoundError",
      "UnitPrintListMarkDraftNotFoundError",
      "UnitPrintListMarkReadyNotFoundError",
      "UnitPrintListPrintSummaryNotFoundError",
      "UnitPrintListSetCustomerCustomerNotFoundError",
      "UnitPrintListSetCustomerNotFoundError",
      "UnitPrintListSetDateDueNotFoundError",
      "UnitPrintListSetManufactureDateCodeNotFoundError",
      "UnitPrintListSetUnitNumberInvalidUnitNumberError",
      "UnitPrintListSetUnitNumberNotFoundError",
      "UnitPrintListUploadJobsInvalidDataError",
      "UnitPrintListUploadJobsNotFoundError"
    ]
  }
};
      export default result;
    