import styled from 'styled-components';
import { DRAWER_WIDTH } from '../constants';

const Wrapper = styled.div`
  margin-top: 64px;
  height: calc(100vh - 64px);
  overflow-y: auto;

  display: flex;
  justify-content: center;

  & > div {
    padding: 2em;
    margin-bottom: 1em;
  }
`;

export const ContentWrapper: React.FC<{ drawerOpen: boolean }> = ({
  children,
  drawerOpen,
}) => (
  <Wrapper style={{ marginLeft: drawerOpen ? DRAWER_WIDTH : 0 }}>
    <div>
      {children}
      <div style={{ height: 100 }}></div>
    </div>
  </Wrapper>
);
