import * as Sentry from '@sentry/react';
import { Route, Switch } from 'react-router-dom';
import { Error } from './Error';

import CustomPrintPage from './CustomPrintPage';
import ManagePage from './ManagePage';
import PrintPage from './PrintPage';

const SentryRoute = Sentry.withSentryRouting(Route);

export interface RoutesProps {
  drawerOpen: boolean;
  setDrawerOpen: (v: boolean) => void;
}

export const Routes: React.FC<RoutesProps> = ({
  drawerOpen,
  setDrawerOpen,
}) => (
  <Switch>
    <SentryRoute path="/custom-print">
      <CustomPrintPage />
    </SentryRoute>
    <SentryRoute path="/manage">
      <ManagePage drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
    </SentryRoute>
    <SentryRoute path="/completed" exact>
      <PrintPage
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        completed
      />
    </SentryRoute>
    <SentryRoute path="/" exact>
      <PrintPage drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
    </SentryRoute>
    <SentryRoute path="*" exact>
      <Error errorText="404. Hmm" fullscreen />
    </SentryRoute>
  </Switch>
);
