import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { SentryLink } from 'apollo-link-sentry';
import { createUploadLink } from 'apollo-upload-client';

import introspectionResult from '../../generated/introspection-result';
import { env } from '../../runtime-environment';

const httpLink = createUploadLink({
  uri: env.serverUri,
});

const authLink = setContext(async (_, { headers }) => ({
  headers: {
    ...headers,
    // Requests for this specific app can be found in Apollo Studio
    'apollographql-client-name': 'autoprint',
    'apollographql-client-version': env.version || 'local',
    // "Authorization" on the VPN
    'x-client-app': 'autoprint',
  },
}));

const cache = new InMemoryCache({
  possibleTypes: introspectionResult.possibleTypes,
  typePolicies: {},
});

const sentryLink = new SentryLink({
  uri: env.serverUri,
  setTransaction: true,
  setFingerprint: true,
  attachBreadcrumbs: {},
});

export const client = new ApolloClient({
  link: ApolloLink.from([authLink, sentryLink, httpLink as any]),
  cache,
});
