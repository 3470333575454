import {
  Container,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from '@mui/material';
import { UnitPrintListQuery } from '../generated/graphql';

type List = NonNullable<UnitPrintListQuery['unitPrintList']>;
type Job = List['printJobs'][0];

export interface ManageTableProps {
  jobs: Job[];
}

const ManageTable: React.FC<ManageTableProps> = ({ jobs }) => {
  if (jobs.length === 0) {
    return (
      <div style={{ height: 150, padding: '3em 0' }}>
        <Typography>No jobs</Typography>
      </div>
    );
  }

  return (
    <Container>
      <Table stickyHeader size="small">
        <TableHead>
          <TableCell>ID</TableCell>
          <TableCell>Product Code</TableCell>
          <TableCell>Barcode</TableCell>
          <TableCell>Quantity</TableCell>
          <TableCell>Type</TableCell>
        </TableHead>
        <TableBody>
          {jobs.map((job) => (
            <TableRow key={job.id}>
              <TableCell>{job.id}</TableCell>
              <TableCell>{job.productCode}</TableCell>
              <TableCell>{job.barcode}</TableCell>
              <TableCell>{job.labelsRequired}</TableCell>
              <TableCell>{job.labelType.name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  );
};

export default ManageTable;
