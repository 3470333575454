import * as Sentry from '@sentry/react';
import Swal from 'sweetalert2';
import sanitizeHtml from 'sanitize-html';

export function getPrintBatchQuantity(
  remaining: number,
  maxRollQuantity: number,
) {
  // If we can fit the remaining labels onto one roll, then do so
  if (remaining <= maxRollQuantity) return remaining;
  // If we can average the remaining over two rolls, then do so
  if (remaining < 2 * maxRollQuantity) return Math.floor(remaining / 2);
  // Otherwise we print a full roll
  return maxRollQuantity;
}

export type CustomMutationResult<TKey extends string> = {
  data?: Partial<
    Record<
      TKey,
      {
        userErrors: Array<{
          message: string;
        }>;
      }
    >
  > | null;
};

export function displayUserErrors<
  TKey extends string,
  TResult extends CustomMutationResult<TKey>,
  TAutoCatch extends boolean | undefined,
>(
  /**
   * Key in the `data` object returned by the mutation to look for `userErrors`
   * in. That is, `data[key].userErrors`.
   */
  key: TKey,
  /** A promise for a triggered mutation */
  mutation: Promise<TResult>,
  /**
   * When true: Automatically adds a `.catch` to the promise, useful for when
   * you want to fire off a mutation and forget about it.
   *
   * When false: Preserves the mutation promise, so you can chain it with other
   * actions. Useful if you want to do something after a mutation succeeds, such
   * as creating a unit print list and automatically selecting it. You should
   * manually add a `.catch` in this case to prevent a crash on rejection.
   */
  autoCatch: TAutoCatch,
): TAutoCatch extends false ? Promise<TResult> : Promise<void | TResult> {
  const mut = mutation.then((res) => {
    const errors = res.data?.[key]?.userErrors;
    if (!errors || errors.length === 0) return res;

    // NB: No sentry reporting - this is a `userError`, not a "real" error
    if (errors.length === 1) {
      Swal.fire({
        icon: 'error',
        title: 'Something went wrong',
        text: errors[0].message,
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'A few things went wrong',
        html: `
<ul style="list-style-position: inside">
  ${errors.map((error) => `<li>${sanitizeHtml(error.message)}</li>`).join('')}
</ul>`,
      });
    }

    return res;
  });

  if (!autoCatch) return mut;
  return mut.catch((error) => {
    console.error(error);
    Sentry.captureException(error);

    Swal.fire(
      'Oops...',
      'Could not complete this request. Please contact IT.',
      'error',
    );
  }) as any;
}
