import { client } from './services/apollo';
import { ApolloProvider } from '@apollo/client';
import { CssBaseline } from '@mui/material';
import AdapterDateFns from '@date-io/date-fns';

import { StylesProvider } from '@mui/styles';
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
  Theme,
  StyledEngineProvider,
} from '@mui/material/styles';
import { Router } from 'react-router-dom';
import { history } from './history';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { AppInner } from './components/AppInner';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

const App: React.FC = () => (
  <ApolloProvider client={client}>
    <StylesProvider injectFirst>
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={materialOverrides}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <CssBaseline />
            <Router history={history}>
              <AppInner />
            </Router>
          </LocalizationProvider>
        </MuiThemeProvider>
      </StyledEngineProvider>
    </StylesProvider>
  </ApolloProvider>
);

const materialOverrides = createTheme({
  palette: {
    primary: {
      main: '#444',
    },
  },
});

export default App;
