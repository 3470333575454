import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import Swal from 'sweetalert2';
import {
  UnitPrintListQuery,
  UnitPrintListStatus,
  useCustomersQuery,
  useSetCustomerMutation,
  useSetDateDueMutation,
  useSetManufactureDateCodeMutation,
  useSetUnitNumberMutation,
} from '../generated/graphql';
import { Error } from './Error';
import { displayUserErrors } from '../util';
import styled from 'styled-components';
import { GenericField } from './GenericField';
import format from 'date-fns/format';
import { useState } from 'react';
import DatePicker from '@mui/lab/DatePicker';

type List = NonNullable<UnitPrintListQuery['unitPrintList']>;

export interface ManageFieldsProps {
  list: List;
}

const ManageField: React.FC<ManageFieldsProps> = ({ list }) => {
  const [setUnitNumber] = useSetUnitNumberMutation();
  const [setManufactureDateCode] = useSetManufactureDateCodeMutation();

  return (
    <Wrapper>
      <SetDateDue unitPrintListId={list.id} dateDue={list.dateDue ?? null} />
      <SelectCustomer
        unitPrintListId={list.id}
        status={list.status}
        hasJobs={list.printJobs.length !== 0}
        customerId={list.customer.id}
      />
      <GenericField
        label="Unit Number"
        value={list.unitNumber}
        mutationKey="result"
        mutate={(v) => {
          return setUnitNumber({
            variables: {
              input: {
                unitPrintListId: list.id,
                unitNumber: v,
              },
            },
            optimisticResponse: {
              result: {
                __typename: 'UnitPrintListSetUnitNumberPayload',
                unitPrintList: {
                  __typename: 'UnitPrintList',
                  id: list.id,
                  unitNumber: v,
                },
                userErrors: [],
              },
            },
          });
        }}
      />
      <GenericField
        label="Manufacture Date Code"
        width={225}
        value={list.manufactureDateCode}
        mutationKey="result"
        mutate={(v) => {
          return setManufactureDateCode({
            variables: {
              input: {
                unitPrintListId: list.id,
                manufactureDateCode: v,
              },
            },
            optimisticResponse: {
              result: {
                __typename: 'UnitPrintListSetManufactureDateCodePayload',
                unitPrintList: {
                  __typename: 'UnitPrintList',
                  id: list.id,
                  manufactureDateCode: v,
                },
                userErrors: [],
              },
            },
          });
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1em -1em;
  & > * {
    margin: 1em;
  }
`;

const SetDateDue: React.FC<{
  unitPrintListId: string;
  dateDue: string | null;
}> = ({ unitPrintListId, dateDue }) => {
  const [mutate] = useSetDateDueMutation();
  const [error, setError] = useState(false);
  const isDesktop = useMediaQuery('@media (pointer: fine)');

  return (
    <DatePicker
      label="Due Date"
      value={dateDue ? new Date(dateDue) : null}
      onChange={(date) => {
        if (
          date != null &&
          (isNaN(date.getTime()) || date.getFullYear() < 2000)
        ) {
          setError(true);
          return;
        }

        if (error) {
          setError(false);
        }

        const value = date == null ? null : format(date, 'yyyy-MM-dd');
        const promise = mutate({
          variables: {
            input: {
              unitPrintListId,
              dateDue: value,
            },
          },
          optimisticResponse: {
            result: {
              __typename: 'UnitPrintListSetDateDuePayload',
              unitPrintList: {
                __typename: 'UnitPrintList',
                id: unitPrintListId,
                dateDue: value,
              },
              userErrors: [],
            },
          },
        });

        displayUserErrors('result', promise, true);
      }}
      inputFormat="dd.MM.yyyy"
      mask="__.__.____"
      renderInput={(props) => (
        <TextField
          variant="standard"
          {...props}
          error={error}
          style={{
            marginTop: 0,
            marginBottom: isDesktop ? -8 : 0,
            width: 130,
          }}
          InputLabelProps={{
            shrink: false,
            style: { fontSize: '1rem', top: -27 },
          }}
        />
      )}
    />
  );
};

const SelectCustomer: React.FC<{
  unitPrintListId: string;
  status: UnitPrintListStatus;
  hasJobs: boolean;
  customerId: string;
}> = ({ unitPrintListId, status, hasJobs, customerId }) => {
  const { data, loading, error } = useCustomersQuery();

  const [mutate] = useSetCustomerMutation();
  const setCustomer = (customerId: string) => {
    const promise = mutate({
      variables: {
        input: {
          unitPrintListId,
          customerId,
        },
      },
      optimisticResponse: {
        result: {
          __typename: 'UnitPrintListSetCustomerPayload',
          unitPrintList: {
            __typename: 'UnitPrintList',
            id: unitPrintListId,
            customer: {
              __typename: 'UnitCustomer',
              id: customerId,
            },
            printJobs: [],
          },
          userErrors: [],
        },
      },
    });

    return displayUserErrors('result', promise, true);
  };

  if (error) return <Error />;

  const customers = loading || !data ? [] : data.unitCustomers;
  const enabled = status === UnitPrintListStatus.Draft;

  const content = (
    <FormControl style={{ marginTop: '1.5em', minWidth: 150 }}>
      <InputLabel
        id="set-customer"
        style={{ fontSize: '1.3rem', top: -5 }}
        shrink
      >
        Customer
      </InputLabel>
      <Select
        labelId="set-customer"
        id="set-customer-select"
        variant="standard"
        value={loading ? '' : customerId}
        disabled={!enabled}
        onChange={async (e) => {
          if (!hasJobs) {
            await setCustomer(e.target.value as string);
            return;
          }

          const result = await Swal.fire({
            title: 'Are you sure',
            text: 'This will clear the list of print jobs',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: "Yes, that's fine",
            confirmButtonColor: '#f50057',
          });

          if (result.isConfirmed) {
            await setCustomer(e.target.value as string);
          }
        }}
      >
        {customers.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  if (enabled) {
    return content;
  }

  return <Tooltip title="Can only modify when a draft">{content}</Tooltip>;
};

export default ManageField;
